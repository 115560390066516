import {useI18n} from "vue-i18n";
import {isNil} from "lodash";

export default function () {
    const {n} = useI18n();

    const percentFormat = (value) => {
        if (!isNil(value)) {
            return n(value, 'percent', 'it');
        }
    }

    const currencyFormat = (value) => {
        if (!isNil(value)) {
            return n(value, 'currency', 'it');
        }
    }

    const decimalFormat = (value) => {
        if (!isNil(value)) {
            return n(value, 'decimal', 'it');
        }
    }

    return {n, decimalFormat, percentFormat, currencyFormat}
}