<template>
  <PaginatedDataTable v-model:model-value="viaggi" :show-search="false" title="">
    <template #columns>

      <!-- codice -->
      <Column :header="t('label.viaggio.codice')" :expander="true">
        <template #body="slotProps">
          {{ slotProps.data.codice }}
        </template>
      </Column>

      <!-- actions -->
      <Column headerStyle="width: 20em" headerClass="p-text-center" bodyClass="p-text-center">
        <template #body="slotProps">
          <Button icon="pi pi-pencil" class="p-button-primary mr-2"
                  @click="showViaggioData(slotProps.data)"
                  v-tooltip.top="t('action.edit')"/>

          <Button icon="pi pi-chart-bar" class="p-button-warning mr-2"
                  @click="showTimelineStateDiagramDialog(slotProps.data)"
                  v-tooltip.top="t('action.flow-diagram')"/>

        </template>
      </Column>

    </template>
  </PaginatedDataTable>
</template>

<script setup>
import PaginatedDataTable from "@/components/common/datatable/PaginatedDataTable"
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import viaggioRestService from "@/services/ViaggioRestService";
import EventService from "@/services/EventService";
import {
  SHOW_VIAGGI_WATCHING_TAB,
  SHOW_VIAGGIO_DATA_DIALOG,
  SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG,
  VIEW_ACTION
} from "@/components/common/constants/Events";
import contrattoRestService from "@/services/ContrattoRestService";
import workflowViaggioRestService from "@/services/WorkflowViaggioRestService";

const {t} = useI18n();
const viaggi = ref([]);

onMounted(async () => {
    await loadViaggiWatching();
    EventService.on(SHOW_VIAGGI_WATCHING_TAB, loadViaggiWatching);
})

async function loadViaggiWatching(){
  viaggi.value = await viaggioRestService.watchingViaggi();
}

function showViaggioData(viaggio) {
  EventService.emit(SHOW_VIAGGIO_DATA_DIALOG, {mode: VIEW_ACTION, item: viaggio});
}

async function showTimelineStateDiagramDialog (it){
  const contratto = (await contrattoRestService.findById(it.contrattoId)).data;
  const viaggio = await viaggioRestService.findByCodice(it.codice);
  const workflow = await workflowViaggioRestService.findStatiByCodiceViaggio(it.codice);

  EventService.emit(SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG, {contratto, viaggio, workflow});
}

</script>


<style scoped>

</style>