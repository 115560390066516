<template>
  <DataTable v-model:value="values"
             v-model:filters="filters"
             :paginator="true"
             :rows="datatableConfig.rows"
             :rowsPerPageOptions="datatableConfig.rowsPerPageOptions"
             :paginatorTemplate="datatableConfig.paginatorTemplate"
             :currentPageReportTemplate="datatableConfig.currentPageReportTemplate"
             responsiveLayout="scroll">

    <template #header>
      <slot name="header">
        <div class="table-header flex flex-column md:flex-row md:justify-content-between">
          <h4 class="m-0 uppercase">{{ title }}</h4>
          <span class="p-input-icon-right" v-if="showSearch">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" :placeholder="t('action.search')"/>
          </span>
        </div>
      </slot>
    </template>


    <slot name="columns"></slot>

  </DataTable>

</template>

<script setup>
import {computed, ref} from "vue";
import {FilterMatchMode} from "primevue/api";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

const props = defineProps({
  title: {type: String, required: true},
  modelValue: {type: Array},
  showSearch: {type: Boolean, required: false, default: true}
});
const emit = defineEmits(['update:modelValue']);

const store = useStore();
const {t} = useI18n();

const datatableConfig = store.getters["config/datatable"];


const filters = ref({global: {value: null, matchMode: FilterMatchMode.CONTAINS}});

const values = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value);
  }
});

</script>

<style scoped>

</style>
