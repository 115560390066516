<template>
  <Button icon="pi pi-download" class="p-button-info mr-2"
          @click="cit23Download"
          v-if="isButtonVisible()"
          v-tooltip.top="t('action.cit23.download')"/>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import viaggioRestService from "@/services/ViaggioRestService";
import {useStore} from "vuex";
import {RITORNO, ANDATA} from "@/components/common/constants/DirezioniViaggio";


const props = defineProps({viaggio: {required: true, type: Object}});

const {t} = useI18n();
const store = useStore();

const cit23Download = () => {
  if (RITORNO === props.viaggio.direzione) {
    viaggioRestService.downloadCit23Pieno(props.viaggio);

  } else if (ANDATA === props.viaggio.direzione) {
    viaggioRestService.downloadCit23Vuoto(props.viaggio);
  }
}

const isButtonVisible = () => {
  const isInParentTreeTable = props.viaggio.parent;
  return !isInParentTreeTable && store.getters['auth/isSpedizioniere'];
}

</script>

<style scoped>

</style>
