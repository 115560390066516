<template>
  <ModalDialog v-model:visible="show"
               :header="dialogTitle"
               @hide="onHide">

    <div class="p-fluid grid">
      <div class="col-12 md:col-12">

        <!-- Generale -->
        <div class="p-fluid formgrid grid">

          <!-- contratto -->
          <div class="field col-12 md:col-4">
            <label for="contrattoId">{{ t("label.viaggio.contratto") }}</label>
            <Dropdown
                id="contrattoId"
                v-model="viaggio.contrattoId"
                :options="contratti"
                option-label="codice"
                option-value="id"
                :filter="true"
                :placeholder="t('message.select-option')"
                :showClear="true"
                :disabled="!isFieldEnabled('contratto')"
                @change="onContrattoChange"/>
            <ErrorMessages field="contrattoId"/>
          </div>

          <!-- muta -->
          <div class="field col-12 md:col-4">
            <label for="mutaId">{{ t("label.viaggio.muta") }}</label>
            <Dropdown
                v-model="viaggio.mutaId"
                :options="mute"
                option-label="descrizione"
                option-value="id"
                :filter="true"
                :placeholder="t('message.select-option')"
                :showClear="true"
                :disabled="!isFieldEnabled('mutaId')"/>
            <ErrorMessages field="mutaId"/>
          </div>

          <div class="field col-12 md:col-4"></div>
        </div>

        <!-- Viaggio vuoto -->
        <Fieldset :legend="t('label.viaggio.direzione.andata')" :toggleable="true" :collapsed="false">
          <div class="p-fluid formgrid grid">

            <!-- timeline -->
            <div class="field col-12 md:col-12">
              <ViaggioTimeline :values="timelineViaggioVuoto"/>
            </div>

            <!-- stazione origine vuoto -->
            <div class="field col-12 md:col-4">
              <label for="stazioneOrigine">{{ t("label.viaggio.vuoto.stazione-origine") }}</label>
              <NodoAutocomplete id="stazioneOrigine"
                                v-model="viaggio.stazioneOrigine"
                                :disabled="!isFieldEnabled('stazioneOrigine')"/>
              <ErrorMessages field="stazioneOrigine"/>
            </div>

            <!-- etd -->
            <div class="field col-12 md:col-4">
              <Label id="etdVuoto" label-key="label.viaggio.etd" help-key="label.viaggio.etd.hint"/>
              <Calendar id="etdVuoto"
                        name="etdVuoto"
                        v-model="viaggio.praticaViaggioVuoto.etd"
                        :show-icon="true"
                        :show-time="true"
                        :show-seconds="false"
                        :manual-input="false"
                        :disabled="!isFieldEnabled('etdVuoto')"/>
              <ErrorMessages field="etdVuoto"/>
            </div>

            <!-- atd -->
            <div class="field col-12 md:col-4">
              <Label id="atdVuoto"
                     label-key="label.viaggio.atd"
                     help-key="label.viaggio.atd.hint"/>
              <Calendar id="atdVuoto"
                        name="atdVuoto"
                        v-model="viaggio.praticaViaggioVuoto.atd"
                        :show-icon="true"
                        :show-time="true"
                        :show-seconds="false"
                        :manual-input="false"
                        :disabled="!isFieldEnabled('atdVuoto')"/>
              <ErrorMessages field="atdVuoto"/>
            </div>

            <!-- stazioni transito -->
            <div class="field col-12 md:col-4">
              <label for="nodo">{{ t("label.viaggio.stazione-transito") }}</label>
              <NodoAutocomplete id="nodo"
                                v-model="stazioneViaggioVuoto.nodo"
                                :disabled="!isFieldEnabled('nodo')"/>
            </div>

            <!-- ett
            <div class="field col-12 md:col-4">
              <Label id="ettVuoto" label-key="label.viaggio.stazione-transito.ett"
                     help-key="label.viaggio.stazione-transito.ett.hint"/>
              <Calendar id="ettVuoto"
                        v-model="stazioneViaggioVuoto.ett"
                        :show-icon="true"
                        :show-time="true"
                        :manual-input="false"
                        :show-seconds="false"
                        :disabled="!isFieldEnabled('ettVuoto')"/>
            </div>-->

            <div class="field mt-5">
              <Button icon="pi pi-plus"
                      class="p-button-rounded p-button-outlined"
                      @click="addStazioneTransitoVuoto"
                      :disabled="!isFieldEnabled('add')"/>
            </div>
            <div class="field col-12 md:col-12">
              <DataTable :value="stazioniViaggioVuoto"
                         :reorderableColumns="false"
                         @rowReorder="onStazioniViaggioVuotoRowReorder"
                         responsiveLayout="scroll"
                         v-if="stazioniViaggioVuoto.length > 0">

                <!-- reorder -->
                <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false"/>

                <!-- position -->
                <Column :header="t('label.position')" :sortable="false" :reorderableColumn="false">
                  <template #body="slotProps">
                    <Badge :value="slotProps.index + 1" size="large" severity="success"></Badge>
                  </template>
                </Column>

                <!-- descrizione -->
                <Column field="nodo.descrizione" :header="t('label.viaggio.stazione-transito')"/>

                <!-- ett -->
                <Column field="ett" :header="t('label.viaggio.stazione-transito.ett')">
                  <template #body="slotProps">
                    <Calendar id="ettVuoto"
                              name="ettVuoto"
                              v-model="slotProps.data.ett"
                              :show-icon="true"
                              :show-time="true"
                              :manual-input="false"
                              :show-seconds="false"
                              :disabled="!isFieldEnabled('ettVuoto')"/>
                  </template>
                </Column>

                <!-- att -->
                <Column :header="t('label.viaggio.stazione-transito.att')">
                  <template #body="slotProps">
                    <Calendar id="attVuoto"
                              v-model="slotProps.data.att"
                              :show-icon="true"
                              :show-time="true"
                              :manual-input="false"
                              :show-seconds="false"
                              :disabled="!isFieldEnabled('attVuoto')"/>
                  </template>
                </Column>

                <!-- azioni -->
                <Column>
                  <template #body="slotProps">
                    <Button icon="pi pi-trash"
                            class="p-button-rounded p-button-outlined p-button-danger"
                            @click="removeStazioneTransitoVuoto(slotProps.data)"
                            :disabled="!isFieldEnabled('delete')"/>
                  </template>
                </Column>

              </DataTable>
            </div>


            <!-- stazione di carico -->
            <div class="field col-12 md:col-4">
              <Label id="stazioneCaricoVuoto" label-key="label.contratto.stazioni-ferrovia.stazione-carico"/>
              <NodoAutocomplete id="stazioneCaricoVuoto"
                                v-model="viaggio.stazioneCarico"
                                :disabled="!isFieldEnabled('stazioneCarico')"/>
            </div>

            <!-- eta -->
            <div class="field col-12 md:col-4">
              <Label id="etaVuoto" label-key="label.viaggio.eta" help-key="label.viaggio.eta.hint"/>
              <Calendar id="etaVuoto"
                        name="etaVuoto"
                        v-model="viaggio.praticaViaggioVuoto.eta"
                        :show-icon="true"
                        :show-time="true"
                        :show-seconds="false"
                        :manual-input="false"
                        :disabled="!isFieldEnabled('etaVuoto')"/>
              <ErrorMessages field="eta"/>
            </div>

            <!-- ata -->
            <div class="field col-12 md:col-4">
              <Label id="ataVuoto"
                     label-key="label.viaggio.ata"
                     help-key="label.viaggio.ata.hint"
              />
              <Calendar id="ataVuoto"
                        name="ataVuoto"
                        v-model="viaggio.praticaViaggioVuoto.ata"
                        :show-icon="true"
                        :show-time="true"
                        :show-seconds="false"
                        :manual-input="false"
                        :disabled="!isFieldEnabled('ataVuoto')"
              />
              <ErrorMessages field="ata"/>
            </div>

            <!-- note stazione di carico -->
            <div class="field col-12 md:col-8">
              <Label id="stazioneCaricoNote" label-key="label.contratto.stazioni-ferrovia.stazione-carico.note"/>
              <Textarea id="stazioneCaricoNote"
                        v-model="viaggio.stazioneCaricoNote"
                        rows="3"
                        :class="[errorStyleClass('stazioneCaricoNote'), 'p-resize-none']"
                        :disabled="!isFieldEnabled('stazioneCaricoNote')"/>
              <ErrorMessages field="stazioneCaricoNote"/>
            </div>

          </div>
        </Fieldset>

        <!-- Viaggio pieno -->
        <Fieldset :legend="t('label.viaggio.direzione.ritorno')" :toggleable="true" :collapsed="false">
          <div class="p-fluid formgrid grid">

            <!-- timeline -->
            <div class="field col-12 md:col-12">
              <ViaggioTimeline :values="timelineViaggioPieno"/>
            </div>

            <!-- stazione di carico -->
            <div class="field col-12 md:col-4">
              <Label id="stazioneCaricoPieno" label-key="label.contratto.stazioni-ferrovia.stazione-carico"/>
              <NodoAutocomplete id="stazioneCaricoPieno"
                                v-model="viaggio.stazioneCarico"
                                :disabled="!isFieldEnabled('stazioneCarico')"/>
            </div>


            <!-- etd -->
            <div class="field col-12 md:col-4">
              <Label id="etdPieno" label-key="label.viaggio.etd" help-key="label.viaggio.etd.hint"/>
              <Calendar id="etdPieno"
                        name="etdPieno"
                        v-model="viaggio.praticaViaggioPieno.etd"
                        :show-icon="true"
                        :show-time="true"
                        :manual-input="false"
                        :disabled="!isFieldEnabled('etdPieno')"/>
              <ErrorMessages field="etdPieno"/>
            </div>

            <!-- atd -->
            <div class="field col-12 md:col-4">
              <Label id="atdPieno"
                     label-key="label.viaggio.atd"
                     help-key="label.viaggio.atd.hint"
              />
              <Calendar id="atdPieno"
                        name="atdPieno"
                        v-model="viaggio.praticaViaggioPieno.atd"
                        :show-icon="true"
                        :show-time="true"
                        :show-seconds="false"
                        :manual-input="false"
                        :disabled="!isFieldEnabled('atdPieno')"
              />
              <ErrorMessages field="atd"/>
            </div>

            <!-- stazioni transito -->
            <div class="field col-12 md:col-4">
              <label for="stazione">{{ t("label.viaggio.stazione-transito") }}</label>
              <NodoAutocomplete id="stazione"
                                v-model="stazioneViaggioPieno.nodo"
                                :disabled="!isFieldEnabled('stazione')"/>
            </div>

            <!-- ett
            <div class="field col-12 md:col-4">
              <Label id="ettPieno" label-key="label.viaggio.stazione-transito.ett"
                     help-key="label.viaggio.stazione-transito.ett.hint"/>
              <Calendar id="ett"
                        v-model="stazioneViaggioPieno.ett"
                        :show-icon="true"
                        :showTime="true"
                        :manual-input="false"
                        :showSeconds="false"
                        :disabled="!isFieldEnabled('ett')"/>
            </div>-->

            <!-- add -->
            <div class="field mt-5">
              <Button icon="pi pi-plus"
                      class="p-button-rounded p-button-outlined"
                      @click="addStazioneTransitoPieno"
                      :v-tooltip="t('action.add')"
                      :disabled="!isFieldEnabled('add')"/>
            </div>

            <div class="field col-12 md:col-12">
              <DataTable :value="stazioniViaggioPieno"
                         :reorderableColumns="false"
                         @rowReorder="onStazioniViaggioPienoRowReorder"
                         responsiveLayout="scroll"
                         v-if="stazioniViaggioPieno.length > 0">

                <!-- reorder -->
                <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false"/>

                <!-- position -->
                <Column :header="t('label.position')" :sortable="false" :reorderableColumn="false">
                  <template #body="slotProps">
                    <Badge :value="slotProps.index + 1" size="large" severity="success"></Badge>
                  </template>
                </Column>

                <!-- descrizione -->
                <Column field="nodo.descrizione" :header="t('label.viaggio.stazione-transito')"/>

                <!-- ett -->
                <Column field="ett" :header="t('label.viaggio.stazione-transito.ett')">
                  <template #body="slotProps">
                    <Calendar id="ettPieno"
                              name="ettPieno"
                              v-model="slotProps.data.ett"
                              :show-icon="true"
                              :show-time="true"
                              :manual-input="false"
                              :show-seconds="false"
                              :disabled="!isFieldEnabled('ettPieno')"/>
                  </template>
                </Column>

                <!-- att -->
                <Column :header="t('label.viaggio.stazione-transito.att')">
                  <template #body="slotProps">
                    <Calendar id="attPieno"
                              v-model="slotProps.data.att"
                              :show-icon="true"
                              :show-time="true"
                              :manual-input="false"
                              :show-seconds="false"
                              :disabled="!isFieldEnabled('attPieno')"/>
                  </template>
                </Column>

                <!-- remove -->
                <Column>
                  <template #body="slotProps">
                    <Button icon="pi pi-trash"
                            class="p-button-rounded p-button-outlined p-button-danger"
                            @click="removeStazioneTransitoPieno(slotProps.data)"
                            :disabled="!isFieldEnabled('del')"/>
                  </template>
                </Column>

              </DataTable>
            </div>

            <!-- stazione di scarico -->
            <div class="field col-12 md:col-4">
              <Label id="idStazioneScarico" label-key="label.contratto.stazioni-ferrovia.stazione-scarico"/>
              <NodoAutocomplete id="idStazioneScarico"
                                v-model="viaggio.stazioneScarico"
                                :disabled="!isFieldEnabled('stazioneScarico')"/>
              <ErrorMessages field="idStazioneScarico"/>
            </div>

            <!-- eta -->
            <div class="field col-12 md:col-4">
              <Label id="etaPieno" label-key="label.viaggio.eta" help-key="label.viaggio.eta.hint"/>
              <Calendar id="etaPieno"
                        name="etaPieno"
                        v-model="viaggio.praticaViaggioPieno.eta"
                        :show-icon="true"
                        :show-time="true"
                        :manual-input="false"
                        :disabled="!isFieldEnabled('etaPieno')"/>
              <ErrorMessages field="etaPieno"/>
            </div>

            <!-- ata -->
            <div class="field col-12 md:col-4">
              <Label id="ataPieno"
                     label-key="label.viaggio.ata"
                     help-key="label.viaggio.ata.hint"
              />
              <Calendar id="ataPieno"
                        name="ataPieno"
                        v-model="viaggio.praticaViaggioPieno.ata"
                        :show-icon="true"
                        :show-time="true"
                        :show-seconds="false"
                        :manual-input="false"
                        :disabled="!isFieldEnabled('ataPieno')"
              />
              <ErrorMessages field="ata"/>
            </div>

            <!-- note stazione scarico -->
            <div class="field col-12 md:col-8">
              <Label id="noteStazioneScarico" label-key="label.contratto.stazioni-ferrovia.stazione-scarico.note"/>
              <Textarea id="noteStazioneScarico"
                        v-model:model-value="viaggio.stazioneScaricoNote"
                        rows="3"
                        :class="[errorStyleClass('noteStazioneScarico'), 'p-resize-none']"
                        :disabled="!isFieldEnabled('stazioneScaricoNote')"/>
              <ErrorMessages field="noteStazioneScarico"/>
            </div>

            <div class="field col-12 md:col-4"></div>

            <!-- date di carico prenotate -->
            <div class="field col-12 md:col-4">
              <label for="dateCaricoPrenotate">{{ t("label.viaggio.date-carico-prenotate") }}</label>
              <Calendar id="dateCaricoPrenotate"
                        v-model="viaggio.dateCaricoPrenotate"
                        selection-mode="range"
                        :manual-input="false"
                        :show-icon="true"
                        :numberOfMonths="2"
                        :disabled="!isFieldEnabled('dateCaricoPrenotate')"/>
            </div>

            <div class="field col-12 md:col-8"/>

            <!-- date di carico previste -->
            <div class="field col-12 md:col-5">
              <label for="dateCaricoPreviste">{{ t("label.viaggio.date-carico-previste") }}</label>
              <Calendar id="dateCaricoPreviste"
                        v-model="viaggio.dateCaricoPreviste"
                        selection-mode="range"
                        :manual-input="false"
                        :show-icon="true"
                        :numberOfMonths="2"
                        :disabled="!isFieldEnabled('dateCaricoPreviste')"/>
            </div>

            <!-- date di carico effettive -->
            <div class="field col-12 md:col-5">
              <label for="dateCaricoEffettive">{{ t("label.viaggio.date-carico-effettive") }}</label>
              <Calendar id="dateCaricoEffettive"
                        v-model="viaggio.dateCaricoEffettive"
                        selection-mode="range"
                        :manual-input="false"
                        :show-icon="true"
                        :numberOfMonths="2"
                        :disabled="!isFieldEnabled('dateCaricoEffettive')"/>
            </div>

            <div class="field col-12 md:col-2"/>

            <!-- date di scarico previste -->
            <div class="field col-12 md:col-5">
              <label for="dateScaricoPreviste">{{ t("label.viaggio.date-scarico-previste") }}</label>
              <Calendar id="dateScaricoPreviste"
                        v-model="viaggio.dateScaricoPreviste"
                        selection-mode="range"
                        :manual-input="false"
                        :show-icon="true"
                        :numberOfMonths="2"
                        :disabled="!isFieldEnabled('dateScaricoPreviste')"/>
            </div>

            <!-- date di scarico effettive -->
            <div class="field col-12 md:col-5">
              <label for="dateScaricoEffettive">{{ t("label.viaggio.date-scarico-effettive") }}</label>
              <Calendar id="dateScaricoEffettive"
                        v-model="viaggio.dateScaricoEffettive"
                        selection-mode="range"
                        :manual-input="false"
                        :show-icon="true"
                        :numberOfMonths="2"
                        :disabled="!isFieldEnabled('dateScaricoEffettive')"/>
            </div>

          </div>
        </Fieldset>

      </div>
    </div>

    <!-- actions -->
    <template #footer>
      <Button :label="t('action.cancel')" icon="pi pi-times" class="p-button p-button-light" @click="hideAction"/>

      <!-- salva -->
      <Button :label="t('action.save')"
              icon="pi pi-check"
              class="p-button p-button-success p-float-right"
              @click="saveViaggioAction"
              v-if="showSalvaViaggioButton()"/>

      <!-- salva date carico -->
      <Button :label="t('action.save')"
              icon="pi pi-check" class="p-button p-button-success p-float-right"
              @click="updateDateCarico"
              v-if="showSalvaDateCarico()"/>

      <!-- salva date scarico -->
      <Button :label="t('action.save')"
              icon="pi pi-check" class="p-button p-button-success p-float-right"
              @click="updateDateScarico"
              v-if="showSalvaDateScarico()"/>

    </template>
  </ModalDialog>
</template>

<script setup>
import {computed, onMounted, provide, ref} from "vue";
import {
  INSERT_ACTION,
  NONE_ACTION,
  SHOW_VIAGGIO_DATA_DIALOG,
  UPDATE_ACTION, VIEW_ACTION
} from "@/components/common/constants/Events";
import ModalDialog from "@/components/common/ModalDialog";
import {useI18n} from "vue-i18n";
import ErrorMessages from "@/components/common/ErrorMessages";
import useVuelidate from "@vuelidate/core";
import {useStore} from "vuex";
import useDateTime from "@/composable/useDateTime";
import {helpers, required} from "@vuelidate/validators";
//import workflowRestService from "@/services/WorkflowRestService";
import NodoAutocomplete from "@/components/common/autocomplete/NodoAutocomplete";
import useCommon from "@/composable/useCommon";
import Label from "@/components/common/Label";
import viaggioRestService from "@/services/ViaggioRestService";
import EventService from "@/services/EventService";
import nodoRestService from "@/services/NodoRestService";

import {
  PIANIFICAZIONE_CHIUSA,
  PIANIFICAZIONE_RIAPERTA, RIPIANIFICAZIONE_OPERATIVA, RIPIANIFICAZIONE_OPERATIVA_CHIUSA, VIAGGIO_CHIUSO,
  VIAGGIO_CREATO, VIAGGIO_INIZIATO
} from "@/components/common/constants/WorkflowStatus";
import ViaggioTimeline from "@/components/viaggio/ViaggioTimeline";
import workflowViaggioRestService from "@/services/WorkflowViaggioRestService";
import {isEmpty, isNil} from "lodash/lang";
import {lowerCase} from "lodash/string";


const {t} = useI18n();
const store = useStore();
const {parseDate} = useDateTime();
const {errorStyleClass} = useCommon();
const show = ref(false);
let mode = ref(NONE_ACTION);


const viaggioDataTemplate = {praticaViaggioVuoto: {}, praticaViaggioPieno: {}};
const viaggio = ref();
const currentStatus = ref();
const mute = computed(() => store.getters["muta/mute"]);
const contratti = computed(() => store.getters['contratto/items']);

// stazioni transito viaggio vuoto
const stazioneViaggioVuoto = ref({});
const stazioniViaggioVuoto = ref([]);
const timelineViaggioVuoto = computed(() => {
  let timeline = [];

  // stazione origine
  if (!isNil(viaggio.value.stazioneOrigine) && !isEmpty(viaggio.value.stazioneOrigine)) {
    const stazioneOrigine = viaggio.value.stazioneOrigine;
    timeline = [{
      nodo: {
        descrizione: stazioneOrigine.descrizione,
        posizione: "origine",
        icona: "pi pi-circle-on"
      }
    }];
  }

  // stazioni transito
  if (!isNil(stazioniViaggioVuoto.value) && !isEmpty(stazioniViaggioVuoto.value)) {
    stazioniViaggioVuoto.value.forEach((s) => {
      const nodo = {nodo: {...s.nodo, ...{icona: "pi pi-ellipsis-h"}}};
      timeline.push(nodo);
    });
  }

  // stazione carico
  if (!isNil(viaggio.value.stazioneCarico) && !isEmpty(viaggio.value.stazioneCarico)) {
    const stazioneCarico = viaggio.value.stazioneCarico;
    timeline = [...timeline, ...[{
      nodo: {
        descrizione: stazioneCarico.descrizione,
        posizione: "carico",
        icona: "pi pi-cloud-upload"
      }
    }]];
  }

  return timeline;
});


// stazioni transito viaggio pieno
const stazioneViaggioPieno = ref({});
const stazioniViaggioPieno = ref([]);
const timelineViaggioPieno = computed(() => {
  let timeline = [];

  // stazione carico
  if (!isNil(viaggio.value.stazioneCarico) && !isEmpty(viaggio.value.stazioneCarico)) {
    const stazioneCarico = viaggio.value.stazioneCarico;
    timeline = [...timeline, ...[{
      nodo: {
        descrizione: stazioneCarico.descrizione,
        posizione: "carico",
        icona: "pi pi-cloud-upload",
        ett: null
      }
    }]];
  }

  // stazioni transito
  if (!isNil(stazioniViaggioPieno.value) && !isEmpty(stazioniViaggioPieno.value)) {
    stazioniViaggioPieno.value.forEach((s) => {
      const nodo = {nodo: {...s.nodo, ...{icona: "pi pi-ellipsis-h"}}};
      timeline.push(nodo);
    });
  }

  // stazione scarico
  if (!isNil(viaggio.value.stazioneScarico) && !isEmpty(viaggio.value.stazioneScarico)) {
    const stazioneScarico = viaggio.value.stazioneScarico;
    timeline = [...timeline, ...[{
      nodo: {
        descrizione: stazioneScarico.descrizione,
        posizione: "carico",
        icona: "pi pi-circle-off",
        ett: null
      }
    }]];
  }

  return timeline;
});


const rules = {
  mutaId: {required: helpers.withMessage(t("validation.required", {field: t("label.viaggio.muta")}), required)},
  contrattoId: {required: helpers.withMessage(t("validation.required", {field: t("label.viaggio.contratto")}), required)},
  stazioneOrigine: {required: helpers.withMessage(t("validation.required", {field: t("label.viaggio.vuoto.stazione-origine")}), required)}
};
const v$ = useVuelidate(rules, viaggio);

onMounted(() => {
  store.dispatch("muta/loadAll");
  store.dispatch("contratto/loadAll");
  EventService.on(SHOW_VIAGGIO_DATA_DIALOG, onShow);
});

const onShow = async (item) => {
  reset();
  show.value = true;
  mode.value = item.mode;

  if (UPDATE_ACTION === mode.value || VIEW_ACTION === mode.value) {
    viaggio.value = await viaggioRestService.findByCodice(item.item.codice);

    const workflow = await workflowViaggioRestService.findByCodiceViaggio(viaggio.value);
    currentStatus.value = workflow.status;

    // stazione origine
    if (!isNil(viaggio.value.stazioneOrigineId)) {
      viaggio.value.stazioneOrigine = await nodoRestService.findById(viaggio.value.stazioneOrigineId);
    }

    // stazione carico
    if (!isNil(viaggio.value.stazioneCaricoId)) {
      viaggio.value.stazioneCarico = await nodoRestService.findById(viaggio.value.stazioneCaricoId);
    }

    // stazione scarico
    if (!isNil(viaggio.value.stazioneScaricoId)) {
      viaggio.value.stazioneScarico = await nodoRestService.findById(viaggio.value.stazioneScaricoId);
    }

    // stazione origine
    if (!isNil(viaggio.value.stazioneOrigineId)) {
      viaggio.value.stazioneOrigine = await nodoRestService.findById(viaggio.value.stazioneOrigineId);
    }

    // vuoto ( eta / etd / atd)
    viaggio.value.praticaViaggioVuoto.eta = parseDate(viaggio.value.praticaViaggioVuoto.eta);
    viaggio.value.praticaViaggioVuoto.etd = parseDate(viaggio.value.praticaViaggioVuoto.etd);
    viaggio.value.praticaViaggioVuoto.atd = parseDate(viaggio.value.praticaViaggioVuoto.atd);
    viaggio.value.praticaViaggioVuoto.ata = parseDate(viaggio.value.praticaViaggioVuoto.ata);

    // pieno (eta / etd / ata)
    viaggio.value.praticaViaggioPieno.eta = parseDate(viaggio.value.praticaViaggioPieno.eta);
    viaggio.value.praticaViaggioPieno.etd = parseDate(viaggio.value.praticaViaggioPieno.etd);
    viaggio.value.praticaViaggioPieno.atd = parseDate(viaggio.value.praticaViaggioPieno.atd);
    viaggio.value.praticaViaggioPieno.ata = parseDate(viaggio.value.praticaViaggioPieno.ata);

    // dateCaricoPreviste (string -> date)
    viaggio.value.dateCaricoPreviste = [];
    const dataCaricoPrevistaDa = parseDate(viaggio.value.dataCaricoPrevistaDa);
    const dataCaricoPrevistaA = parseDate(viaggio.value.dataCaricoPrevistaA);
    if (!isNil(dataCaricoPrevistaDa)) {
      viaggio.value.dateCaricoPreviste[0] = dataCaricoPrevistaDa;
    }
    if (!isNil(dataCaricoPrevistaA)) {
      viaggio.value.dateCaricoPreviste[1] = dataCaricoPrevistaA;
    }

    // dateCaricoEffettive (string -> date)
    viaggio.value.dateCaricoEffettive = [];
    const dataCaricoEffettivaDa = parseDate(viaggio.value.dataCaricoEffettivaDa);
    const dataCaricoEffettivaA = parseDate(viaggio.value.dataCaricoEffettivaA);
    if (!isNil(dataCaricoEffettivaDa)) {
      viaggio.value.dateCaricoEffettive[0] = dataCaricoEffettivaDa;
    }
    if (!isNil(dataCaricoEffettivaA)) {
      viaggio.value.dateCaricoEffettive[1] = dataCaricoEffettivaA;
    }

    // dateScaricoPreviste (string -> date)
    viaggio.value.dateScaricoPreviste = [];
    const dataScaricoPrevistaDa = parseDate(viaggio.value.dataScaricoPrevistaDa);
    const dataScaricoPrevistaA = parseDate(viaggio.value.dataScaricoPrevistaA);
    if (!isNil(dataScaricoPrevistaDa)) {
      viaggio.value.dateScaricoPreviste[0] = dataScaricoPrevistaDa;
    }
    if (!isNil(dataScaricoPrevistaA)) {
      viaggio.value.dateScaricoPreviste[1] = dataScaricoPrevistaA;
    }

    // dateScaricoEffettive (string -> date)
    viaggio.value.dateScaricoEffettive = [];
    const dataScaricoEffettivaDa = parseDate(viaggio.value.dataScaricoEffettivaDa);
    const dataScaricoEffettivaA = parseDate(viaggio.value.dataScaricoEffettivaA);
    if (!isNil(dataScaricoEffettivaDa)) {
      viaggio.value.dateScaricoEffettive[0] = dataScaricoEffettivaDa;
    }
    if (!isNil(dataScaricoEffettivaA)) {
      viaggio.value.dateScaricoEffettive[1] = dataScaricoEffettivaA;
    }

    // dataCaricoPrenotata (string -> date)
    viaggio.value.dateCaricoPrenotate = [];
    const dataCaricoPrenotataDa = parseDate(viaggio.value.dataCaricoPrenotataDa);
    const dataCaricoPrenotataA = parseDate(viaggio.value.dataCaricoPrenotataA);
    if (!isNil(dataCaricoPrenotataDa)) {
      viaggio.value.dateCaricoPrenotate[0] = dataCaricoPrenotataDa;
    }
    if (!isNil(dataCaricoPrenotataA)) {
      viaggio.value.dateCaricoPrenotate[1] = dataCaricoPrenotataA;
    }

    // stazioniViaggioVuoto
    const nodiTransitoVuoto = viaggio.value.praticaViaggioVuoto.nodiTransito;
    nodiTransitoVuoto.forEach((nodo) => {
      nodo.ett = parseDate(nodo.ett);
      nodo.att = parseDate(nodo.att);
      stazioniViaggioVuoto.value.push(nodo);
    });

    // stazioniViaggioPieno
    const nodiTransitoPieno = viaggio.value.praticaViaggioPieno.nodiTransito;
    nodiTransitoPieno.forEach((nodo) => {
      nodo.ett = parseDate(nodo.ett);
      nodo.att = parseDate(nodo.att);
      stazioniViaggioPieno.value.push(nodo);
    });
  }
}

const reset = () => {
  v$.value.$reset();
  stazioniViaggioVuoto.value = [];
  stazioneViaggioVuoto.value = {}
  stazioniViaggioPieno.value = [];
  stazioneViaggioPieno.value = {};
  viaggio.value = Object.assign({}, {...viaggioDataTemplate});
}

const onHide = () => {
  reset();
}

const dialogTitle = computed(() => {
  return mode.value === INSERT_ACTION ? t("label.viaggio.titolo.insert") : t("label.viaggio.titolo.edit");
})

const hideAction = () => {
  show.value = false;
}

const addStazioneTransitoVuoto = () => {
  stazioniViaggioVuoto.value.push({...stazioneViaggioVuoto.value});
  stazioneViaggioVuoto.value = {};
}

const removeStazioneTransitoVuoto = (row) => {
  stazioniViaggioVuoto.value.splice(stazioniViaggioVuoto.value.indexOf(row), 1);
}

const addStazioneTransitoPieno = () => {
  stazioniViaggioPieno.value.push({...stazioneViaggioPieno.value});
  stazioneViaggioPieno.value = {};
}

const removeStazioneTransitoPieno = (row) => {
  stazioniViaggioPieno.value.splice(stazioniViaggioPieno.value.indexOf(row), 1);
}


const onStazioniViaggioVuotoRowReorder = (e) => {
  stazioniViaggioVuoto.value = e.value;
}

const onStazioniViaggioPienoRowReorder = (e) => {
  stazioniViaggioPieno.value = e.value;
}

const onContrattoChange = async (e) => {
  const contrattiSelected = contratti.value.filter(c => c.id === e.value);
  const contrattoSelected = contrattiSelected[0];

  // stazione carico (origine)
  const idStazioneCarico = contrattoSelected.idStazioneOrigine;
  const stazioneCarico = await nodoRestService.findById(idStazioneCarico);
  viaggio.value.stazioneCarico = !isEmpty(stazioneCarico) ? stazioneCarico : {}
  viaggio.value.stazioneCaricoNote = contrattiSelected.noteStazioneOrigine;

  // stazione scarico (destinazione)
  const idStazioneScarico = contrattoSelected.idStazioneDestinazione;
  const stazioneScarico = await nodoRestService.findById(idStazioneScarico);
  viaggio.value.stazioneScarico = !isEmpty(stazioneScarico) ? stazioneScarico : {};
  viaggio.value.noteStazioneScarico = contrattoSelected.noteStazioneDestinazione;
}


const saveViaggioAction = async () => {
  await v$.value.$validate();
  if (!v$.value.$invalid) {

    // stazioneCaricoId da stazioneCarico
    viaggio.value.stazioneCaricoId = viaggio.value.stazioneCarico.id;

    // stazioneOrigineId da stazioneOrigine
    viaggio.value.stazioneOrigineId = viaggio.value.stazioneOrigine.id;

    // stazioneScaricoId da stazioneScarico
    viaggio.value.stazioneScaricoId = viaggio.value.stazioneScarico.id;

    // date carico previste
    const dateCaricoPreviste = viaggio.value.dateCaricoPreviste;
    if (!isNil(dateCaricoPreviste) && 2 === dateCaricoPreviste.length) {
      viaggio.value.dataCaricoPrevistaDa = dateCaricoPreviste[0];
      viaggio.value.dataCaricoPrevistaA = dateCaricoPreviste[1];
    }

    // date carico effettive
    const dateCaricoEffettive = viaggio.value.dateCaricoEffettive;
    if (!isNil(dateCaricoEffettive) && 2 === dateCaricoEffettive.length) {
      viaggio.value.dataCaricoEffettivaDa = dateCaricoEffettive[0];
      viaggio.value.dataCaricoEffettivaA = dateCaricoEffettive[1];
    }

    // date scarico previste
    const dateScaricoPreviste = viaggio.value.dateScaricoPreviste;
    if (!isNil(dateScaricoPreviste) && 2 === dateScaricoPreviste.length) {
      viaggio.value.dataScaricoPrevistaDa = dateScaricoPreviste[0];
      viaggio.value.dataScaricoPrevistaA = dateCaricoPreviste[1];
    }

    // date scarico effettive
    const dateScaricoEffettive = viaggio.value.dateScaricoEffettive;
    if (!isNil(dateScaricoEffettive) && 1 === dateScaricoEffettive.length) {
      viaggio.value.dateScaricoEffettivaDa = dateScaricoEffettive[0];
      viaggio.value.dateScaricoEffettiveA = dateScaricoEffettive[1];
    }


    // date carico prenotate
    const dateCaricoPrenotate = viaggio.value.dateCaricoPrenotate;
    if (!isNil(dateCaricoPrenotate) && 2 === dateCaricoPrenotate.length) {
      viaggio.value.dataCaricoPrenotataDa = dateCaricoPrenotate[0];
      viaggio.value.dataCaricoPrenotataA = dateCaricoPrenotate[1];
    }

    // nodi transito (vuoto e pieno)
    viaggio.value.praticaViaggioVuoto.nodiTransito = stazioniViaggioVuoto.value;
    viaggio.value.praticaViaggioPieno.nodiTransito = stazioniViaggioPieno.value;

    const fullAction = "viaggio/" + mode.value;
    await store.dispatch(fullAction, viaggio.value);
    hideAction();
  }
}

const updateDateCarico = async () => {
  const dateCaricoPreviste = viaggio.value.dateCaricoPreviste;
  const dateCaricoEffettive = viaggio.value.dateCaricoEffettive;
  const data = {rangeDatePreviste: dateCaricoPreviste, rangeDateEffettive: dateCaricoEffettive};
  await viaggioRestService.updateDateCarico(viaggio.value, data);
  hideAction();
}

const updateDateScarico = async () => {
  const dateScaricoPreviste = viaggio.value.dateScaricoPreviste;
  const dateScaricoEffettive = viaggio.value.dateScaricoEffettive;
  const data = {rangeDatePreviste: dateScaricoPreviste, rangeDateEffettive: dateScaricoEffettive};
  await viaggioRestService.updateDateScarico(viaggio.value, data);
  hideAction();
}


// permessi --------------------------------------------------------------------------

// TODO: le logiche di filtro devono essere lato server (dati + status di ciascun campo)
// TODO: metodo con riferimento ad ACL
const isFieldEnabled = (field) => {
  const isExecutionManager = store.getters['auth/isExecutionManager'];
  const isSpedizioniere = store.getters['auth/isSpedizioniere'];
  const isCaricatore = store.getters['auth/isCaricatore'];
  const isScaricatore = store.getters['auth/isScaricatore'];

  // campi di tipo AT(X) "actual" sia per viaggio vuoto che per viaggio pieno
  const ACTUAL_TIME_FIELDS = ['atdPieno', 'ataPieno', 'attPieno', 'attVuoto', 'atdVuoto', 'ataVuoto'];
  const ACTUAL_CARICO_SCARICO_FIELDS = ['dateCaricoEffettive', 'dateScaricoEffettive'];

  // campi di tipo ET(X) "extimated" sia per viaggio vuoto che per viaggio pieno
  const EXPECTED_TIME_FIELDS = ['etdPieno', 'etaPieno', 'ettPieno', 'ettVuoto', 'etdVuoto', 'etaVuoto'];
  const EXPECTED_CARICO_SCARICO_FIELDS = ['dateCaricoPreviste', 'dateScaricoPreviste'];

  // insert: nuovo viaggio da creare
  if (mode.value === INSERT_ACTION) {
    return isSpedizioniere && !ACTUAL_TIME_FIELDS.includes(field) &&
        !ACTUAL_CARICO_SCARICO_FIELDS.includes(field) && !EXPECTED_CARICO_SCARICO_FIELDS.includes(field);
  }

  // view: all fields readonly
  if (VIEW_ACTION === mode.value) {
    return false;
  }

  // edit: modifica viaggio
  if (mode.value === UPDATE_ACTION) {

    // viaggio creato e ripianificazione
    if (lowerCase(VIAGGIO_CREATO) === lowerCase(currentStatus.value) || lowerCase(PIANIFICAZIONE_RIAPERTA) === lowerCase(currentStatus.value)) {

      if (isExecutionManager) {
        return !ACTUAL_TIME_FIELDS.includes(field) && !ACTUAL_CARICO_SCARICO_FIELDS.includes(field);
      }

      if (isSpedizioniere) {
        return !ACTUAL_TIME_FIELDS.includes(field) && !ACTUAL_CARICO_SCARICO_FIELDS.includes(field) && !EXPECTED_CARICO_SCARICO_FIELDS.includes(field);
      }

      if (isCaricatore) {
        return "dateCaricoPreviste" === field;
      }
      if (isScaricatore) {
        return "dateScaricoPreviste" === field;
      }

      return false;
    }

    // pianificazione chiusa
    if (lowerCase(PIANIFICAZIONE_CHIUSA) === lowerCase(currentStatus.value)) {
      return false;
    }

    // viaggio started
    if (lowerCase(VIAGGIO_INIZIATO) === lowerCase(currentStatus.value)) {
      if (isExecutionManager) {
        return ACTUAL_TIME_FIELDS.includes(field) || ACTUAL_CARICO_SCARICO_FIELDS.includes(field);
      }

      if (isSpedizioniere) {
        return ACTUAL_TIME_FIELDS.includes(field);
      }

      if (isCaricatore) {
        return "dateCaricoEffettive" === field;
      }

      if (isScaricatore) {
        return "dateScaricoEffettive" === field;
      }

      return false;
    }

    // ripianificazione operativa
    if (lowerCase(RIPIANIFICAZIONE_OPERATIVA) === lowerCase(currentStatus.value)) {

      if (isExecutionManager) {
        return !ACTUAL_TIME_FIELDS.includes(field) && !ACTUAL_CARICO_SCARICO_FIELDS.includes(field);
      }

      if (isSpedizioniere) {

        // NUOVA GESTIONE ------------------------
        if (EXPECTED_TIME_FIELDS.includes(field)) {

          // vuoto
          if ('etdVuoto' === field && isNil(viaggio.value.praticaViaggioVuoto.atd)) {
            return true;
          }
          if ('etaVuoto' === field && isNil(viaggio.value.praticaViaggioVuoto.ata)) {
            return true;
          }
          if ('ettVuoto' === field && isNil(viaggio.value.praticaViaggioVuoto.att)) {
            return true;
          }

          // pieno
          if ('etdPieno' === field && isNil(viaggio.value.praticaViaggioPieno.atd)) {
            return true;
          }
          if ('etaPieno' === field && isNil(viaggio.value.praticaViaggioPieno.ata)) {
            return true;
          }
          if ('ettPieno' === field && isNil(viaggio.value.praticaViaggioPieno.att)) {
            return true;
          }

        }

        return false;

        // --------------------------------------------
        // vecchia gestione
        //return EXPECTED_TIME_FIELDS.includes(field);
      }

      if (isCaricatore) {
        return "dateCaricoPreviste" === field;
      }

      if (isScaricatore) {
        return "dateScaricoPreviste" === field;
      }

    }

    // ripianificazione operativa chiusa
    if (lowerCase(RIPIANIFICAZIONE_OPERATIVA_CHIUSA) === lowerCase(currentStatus.value)) {
      if (isSpedizioniere) {
        return ACTUAL_TIME_FIELDS.includes(field);
      }

      if (isExecutionManager) {
        return ACTUAL_TIME_FIELDS.includes(field) || ACTUAL_CARICO_SCARICO_FIELDS.includes(field);
      }

      if (isCaricatore) {
        return "dateCaricoEffettive" === field;
      }

      if (isScaricatore) {
        return "dateScaricoEffettive" === field;
      }

      return false;
    }

  }


  return false;
}
// ----------------------------------------------------------------------------


// Logiche bottoni salvataggio --------------------------------------------------------
// TODO: accorpare con i bottoni come componenti ad hoc
// TODO: logiche lato server
const showSalvaViaggioButton = () => {
  const isPianificazioneChiusa = (PIANIFICAZIONE_CHIUSA === currentStatus.value);
  const isViaggioChiuso = (VIAGGIO_CHIUSO === currentStatus.value);
  const isViewMode = VIEW_ACTION === mode.value;
  return (store.getters['auth/isSpedizioniere'] || store.getters['auth/isExecutionManager']) && !isPianificazioneChiusa
      && !isViaggioChiuso && !isViewMode;
}

// TODO: logiche lato server e gestione stato actuals
const showSalvaDateCarico = () => {
  const isViaggioChiuso = (VIAGGIO_CHIUSO === currentStatus.value);
  return store.getters['auth/isCaricatore'] && !isViaggioChiuso;
}

// TODO: logiche lato server e gestione stato actuals
const showSalvaDateScarico = () => {
  const isViaggioChiuso = (VIAGGIO_CHIUSO === currentStatus.value);
  return store.getters['auth/isScaricatore'] && !isViaggioChiuso;
}
// --------------------------------------------------------------------------------------


provide('v$', v$);
</script>

<style scoped>
::v-deep(.p-datatable-reorderablerow-handle) {
  font-size: 40px;
}

</style>
