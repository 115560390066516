<template>
  <Button icon="pi pi-download" class="p-button-success mr-2"
          @click="cuvDownload(viaggio)"
          v-if="isButtonVisible(viaggio)"
          v-tooltip.top="t('action.cuv.download')"/>
</template>

<script setup>
import viaggioRestService from "@/services/ViaggioRestService";
import {isEmpty} from "lodash";
import {ANDATA} from "@/components/common/constants/DirezioniViaggio";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

defineProps({viaggio: {type: Object, required: true}});

const {t} = useI18n();
const store = useStore();

const cuvDownload = (viaggio) => {
  viaggioRestService.downloadCuv(viaggio);
}

const isButtonVisible = (viaggio) => {
  const isSpedizioniere = store.getters['auth/isSpedizioniere'];
  const isRitorno = !isEmpty(viaggio) ? (ANDATA === viaggio.direzione) : false;
  return isSpedizioniere && isRitorno;
}

</script>

<style scoped>

</style>
