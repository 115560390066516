import httpClient from "@/services/HttpService";

export default {

    findAll: async (id) => {
        return (await httpClient.get(`/watcher/viaggio/${id}`)).data;
    },
    enable: async (id) => {
        return (await httpClient.patch(`/watcher/viaggio/${id}/enable`)).data;
    },
    disable: async (id) => {
        return (await httpClient.patch(`/watcher/viaggio/${id}/disable`)).data;
    },
    delete: async (id) => {
        return (await httpClient.delete(`/watcher/viaggio/${id}`)).data;
    },
    insert: async (watcher) => {
        return (await httpClient.post(`/watcher/viaggio`, watcher)).data;
    }
}
