const CREATO = "CREATED";
const INIZIATO = "STARTED";
const CONTRATTO_INSERITO = "CONTRACT INSERTED";
const CONTRATTO_VALIDATO = "CONTRACT VALIDATED";
const EXECUTIONMANAGER_SELECTED = "EXECUTION MANAGER SELECTED";
const SOGGETTI_INSERITI = "COMPANIES INSERTED";
const VIAGGIO_CREATO = "VOYAGE CREATED";
const SPEDIZIONIERE_SELECTED = "RAIL FORWARDER SELECTED";
const PIANIFICAZIONE_SALVATA = "PLANNING SAVED";
const PIANIFICAZIONE_UPDATE_DATE_CARICO = "UPDATE LOADING TIME WINDOW";
const PIANIFICAZIONE_UPDATE_DATE_SCARICO = "UPDATE UNLOADING TIME WINDOW";
const PIANIFICAZIONE_CHIUSA = "PLANNING CLOSED";
const PIANIFICAZIONE_RIAPERTA = "PLANNING REOPENED";
const VIAGGIO_INIZIATO = "VOYAGE CONFIRMED";
const RIPIANIFICAZIONE_OPERATIVA = "OPERATIONAL RESCHEDULING";
const RIPIANIFICAZIONE_OPERATIVA_CHIUSA = "OPERATIONAL RESCHEDULING CLOSED";
const VIAGGIO_CHIUSO = "VOYAGE CLOSED";
const FINE = "END";

export {
    CREATO,
    INIZIATO,
    CONTRATTO_INSERITO,
    CONTRATTO_VALIDATO,
    EXECUTIONMANAGER_SELECTED,
    SOGGETTI_INSERITI,
    VIAGGIO_CREATO,
    SPEDIZIONIERE_SELECTED,
    PIANIFICAZIONE_SALVATA,
    PIANIFICAZIONE_UPDATE_DATE_CARICO,
    PIANIFICAZIONE_UPDATE_DATE_SCARICO,
    PIANIFICAZIONE_CHIUSA,
    PIANIFICAZIONE_RIAPERTA,
    VIAGGIO_INIZIATO,
    RIPIANIFICAZIONE_OPERATIVA,
    RIPIANIFICAZIONE_OPERATIVA_CHIUSA,
    VIAGGIO_CHIUSO,
    FINE
}
