<template>
  <Button icon="pi pi-user-edit" class="p-button-help mr-2"
          @click="showDialog"
          v-if="isButtonVisible"
          v-tooltip.top="t('action.aggiungi-impresa-ferroviaria')"/>

  <ModalDialog v-model:visible="isDialogVisible"
               :header="t('label.select-railway-company')"
               position="center"
               :draggable="false"
               :maximizable="false"
               :modal="false"
               @show="onShow"
               @hide="onHide"
               class="dialog"
               style="width: 30vw; height: 40vh;">

    <div class="field col-12 md:col-12">
      <label for="impresaFerroviaria">{{ t('label.select-railway-company') }}</label>
      <div class="p-inputgroup">
        <Dropdown
            id="impresaFerroviaria"
            v-model="impresaFerroviaria"
            :options="impreseFerroviarie"
            optionLabel="nomeBreve"
            :placeholder="t('message.select-option')"
            :show-clear="true"
            :filter="true"
            :class="errorStyleClass(v$, 'impresaFerroviaria')">
        </Dropdown>
        <Button @click="invitaSoggetto(Roles.IMPRESA_FERROVIARIA)"
                icon="pi pi-user-plus"
                :label="t('label.soggetto.invita-impresa-ferroviaria')"
                class="p-button-sm p-button-success invite-button"
                v-tooltip.top="t('label.soggetto.invita-impresa-ferroviaria')"/>
      </div>
      <ErrorMessages field="impresaFerroviaria"/>
    </div>


    <!-- actions -->
    <template #footer>
      <Button :label="t('action.cancel')" icon="pi pi-times" class="p-button p-button-light" @click="hide"/>
      <Button :label="t('action.save')" icon="pi pi-check" class="p-button p-button-success p-float-right"
              @click="save"/>
    </template>

  </ModalDialog>

</template>

<script setup>
import {ref, onMounted, provide} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import ModalDialog from "@/components/common/ModalDialog";
import EventService from "@/services/EventService";
import {SHOW_SOGGETTO_RICHIESTA_DATA_DIALOG} from "@/components/common/constants/Events";
import Roles from "@/components/common/constants/Roles";
import ErrorMessages from "@/components/common/ErrorMessages";
import useCommon from "@/composable/useCommon";
import soggettoRestService from "@/services/SoggettoRestService";

import useVuelidate from "@vuelidate/core";
//import contrattoRestService from "@/services/ContrattoRestService";
import viaggioRestService from "@/services/ViaggioRestService";
import useMessage from "@/composable/useMessage";
import {helpers, required} from "@vuelidate/validators";


const props = defineProps({viaggio: {required: true}});
const store = useStore();
const {t} = useI18n();
const {errorStyleClass} = useCommon();
const {success} = useMessage();

const impresaFerroviaria = ref();
const impreseFerroviarie = ref([]);
const isDialogVisible = ref(false);
const isButtonVisible = ref(false);

const rules = {
  impresaFerroviaria: {required: helpers.withMessage(t("validation.required", {field: t("label.impresa-ferroviaria")}), required)}
};
const v$ = useVuelidate(rules, impresaFerroviaria, {$scope: 'selectImpFerr'});


onMounted(() => {
  checkButtonVisibility();
})

const showDialog = () => {
  isDialogVisible.value = true;
}

const save = async () => {
  await v$.value.$validate();
  if (!v$.value.$invalid) {
    await viaggioRestService.selectImpresaFerroviaria(props.viaggio, impresaFerroviaria.value);
    success();
    hide();
  }
}

const hide = () => {
  isDialogVisible.value = false;
}

const onShow = async () => {
  impreseFerroviarie.value = await soggettoRestService.impreseFerroviarie();

  const viaggioCompleto = await viaggioRestService.findByCodice(props.viaggio.codice);
  if (null != viaggioCompleto) {
    for (const impresa of impreseFerroviarie.value) {
      if (null != impresa && impresa.eori === viaggioCompleto.impresaFerroviariaEori) {
        impresaFerroviaria.value = impresa;
      }
    }
  }

}

const onHide = () => {
  v$.value.$reset();
}

const checkButtonVisibility = async () => {
  const isSpedizioniere = store.getters['auth/isSpedizioniere'];
  const isInViaggioContainer = props.viaggio.parent;
  isButtonVisible.value = isInViaggioContainer && isSpedizioniere;
}

const invitaSoggetto = (ruolo) => {
  EventService.emit(SHOW_SOGGETTO_RICHIESTA_DATA_DIALOG, {ruolo: ruolo});
}

provide("v$", v$);
</script>

<style scoped>

.dialog {

}

</style>