<template>

  <!-- add transit station -->
  <div style="text-align: center">
    <Button icon="pi pi-plus" class="p-button-outlined p-button-help p-button-secondary" @click="addNodoTransito"
            v-tooltip.bottom="t('action.viaggio.add.transit-station')"/>
  </div>

  <Timeline :value="nodi" layout="horizontal">
    <template #marker="slotProps">

      <!-- add
      <div v-if="slotProps.item.addButton">
        <Button icon="pi pi-plus" class="p-button-rounded p-button-outlined" @click="addNodoTransito"/>
      </div> -->

      <!-- nodo -->
      <span @click="showNodoDetailsDialog(slotProps.item)" v-tooltip.bottom="slotProps.item.luogoLabel">
        <i :class="slotProps.item.icona" style="font-size: 25px" :style="{'color': slotProps.item.icona.coloreIcona}"/>
      </span>

    </template>
    <template #opposite="slotProps">

      <div class="opposite-container">

        <!-- data stimata -->
        <div>
          {{ dateTimeFormat(slotProps.item.dataStimata) }}
        </div>

        <!-- data effettiva -->
        <div>
          {{ dateTimeFormat(slotProps.item.dataEffettiva) }}
        </div>
      </div>
    </template>
    <template #content="slotProps">

      <div v-if="!slotProps.item.addButton">

        <!-- luogo -->
        <div>
          {{ slotProps.item.luogo.descrizione }}
        </div>


        <!-- note -->
        <div>
          {{ slotProps.item.note }}
        </div>
      </div>

    </template>
  </Timeline>


  <!-- dialog -->
  <Dialog v-model:visible="displayDialog">
    <div class="p-fluid">

      <!-- luogo -->
      <div class="p-field">
        <label for="luogo">{{ nodoSelezionato.luogoLabel }}</label>
        <NodoAutocomplete id="luogo" v-model="nodoSelezionato.luogo" :disabled="nodoSelezionato.luogoDisabilitato"/>
      </div>

      <!-- dataStimata -->
      <div class="p-field">
        <label for="dataStimata">{{ nodoSelezionato.dataStimataLabel }}</label>
        <Calendar
            id="dataStimata"
            v-model="nodoSelezionato.dataStimata"
            :show-time="true"
            :show-icon="true"
            :manual-input="false"
            :disabled="nodoSelezionato.dataStimataDisabilitata"/>
      </div>

      <!-- dataEffettiva -->
      <div class="p-field">
        <label for="dataEffettiva">{{ nodoSelezionato.dataEffettivaLabel }}</label>
        <Calendar
            id="dataEffettiva"
            v-model="nodoSelezionato.dataEffettiva"
            :show-time="true"
            :show-icon="true"
            :manual-input="false"
            :disabled="nodoSelezionato.dataEffettivaDisabilitata"/>
      </div>

      <!-- note -->
      <div class="p-field">
        <label for="note">{{ nodoSelezionato.noteLabel }}</label>
        <Textarea id="note" rows="5" cols="15" v-model="nodoSelezionato.note"></Textarea>
      </div>

    </div>
  </Dialog>

</template>

<script setup>
import {computed, ref} from "vue";
import {sortBy} from "lodash";
import useDateTime from "@/composable/useDateTime";
import {useI18n} from "vue-i18n";
import NodoAutocomplete from "@/components/common/autocomplete/NodoAutocomplete";


const props = defineProps({
  origine: {type: Object, required: true},
  transito: {type: Array, required: true},
  destinazione: {type: Object, required: true},
});

const emit = defineEmits(["update:nodoOrigine", "update:nodiTransito", "update:nodoDestinazione"]);

const {t} = useI18n();
const {dateTimeFormat} = useDateTime();

const displayDialog = ref(false);
const nodoSelezionato = ref();

const nodoOrigine = computed({
  get: () => props.origine,
  set: (value) => {
    emit("update:nodoOrigine", value);
  }
});

const nodiTransito = computed({
  get: () => props.transito,
  set: (values) => {
    emit("update:nodiTransito", values);
  }
});

const nodoDestinazione = computed({
  get: () => props.destinazione,
  set: (value) => {
    emit("update:nodoDestinazione", value);
  }
});

const nodi = computed(() => {
  let output = [];

  // nodo origine
  output.push(nodoOrigine.value);

  // nodi transito
  const nodiTransitoOrdinati = sortBy(nodiTransito.value, ['dataPrevista', 'asc']); // TODO: ordinamento per data
  var halfLen = Math.ceil(nodiTransitoOrdinati.length / 2);
  let nodiOrdinatiPrimaParte = nodiTransitoOrdinati.slice(0, halfLen);
  output = [...output, ...nodiOrdinatiPrimaParte];

  // add button
  //output.push({addButton: true});

  let nodiOrdinatiSecondaParte = nodiTransitoOrdinati.slice(halfLen, nodiTransitoOrdinati.length);
  output = [...output, ...nodiOrdinatiSecondaParte];

  // nodo destinazione
  output.push(nodoDestinazione.value);

  return output;
});

const addNodoTransito = () => {
  nodoSelezionato.value = {
    luogoLabel: t('label.viaggio.stazione-transito'),
    luogo: {descrizione: ""},
    dataStimataLabel: t('label.viaggio.stazione-transito.ett'),
    dataStimata: null,
    dataStimataDisabilitata: false,
    dataEffettivaLabel: t('label.viaggio.stazione-transito.att'),
    dataEffettiva: null,
    dataEffettivaDisabilitata: false,
    noteLabel: t('label.note'),
    note: "",
    icona: "pi pi-ellipsis-h",
  };
  nodiTransito.value.push(nodoSelezionato.value);
  displayDialog.value = true;
}

const showNodoDetailsDialog = (nodo) => {
  nodoSelezionato.value = nodo;
  displayDialog.value = true;
};

</script>
<style lang="scss">
.opposite-container {
  text-align: left;
  min-width: 110px;
}


</style>
