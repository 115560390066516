<template>
  <Button icon="pi pi-circle-off" class="p-button-warning mr-2"
          @click="showPianificazioneDialog"
          v-if="isButtonVisible"
          v-tooltip.top="t('action.pianificazione-reale')"/>
</template>

<script setup>
import EventService from "@/services/EventService";
import {SHOW_PIANIFICAZIONE_DATA_DIALOG} from "@/components/common/constants/Events";
import {isEmpty} from "lodash";
import {RITORNO} from "@/components/common/constants/DirezioniViaggio";
import {useI18n} from "vue-i18n";
import {ref, onMounted} from "vue";
import {useStore} from "vuex";

const {t} = useI18n();
const store = useStore();
const props = defineProps({viaggio: {required: true}});

const isButtonVisible = ref(false);

onMounted(() => {
  checkButtonVisibility();
})

const showPianificazioneDialog = () => {
  EventService.emit(SHOW_PIANIFICAZIONE_DATA_DIALOG, Object.assign({}, props.viaggio));
}

const checkButtonVisibility = () => {
  const isViaggioRitorno = !isEmpty(props.viaggio) ? (RITORNO === props.viaggio.direzione) : false;
  const isExecutionManager = store.getters['auth/isExecutionManager'];
  const isSpedizioniere = store.getters['auth/isSpedizioniere'];
  isButtonVisible.value = isViaggioRitorno && (isExecutionManager || isSpedizioniere);
}

</script>

<style scoped>

</style>