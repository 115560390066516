<template>
  <Button icon="pi pi-pencil" class="p-button-primary mr-2"
          @click="showEditViaggioDialog"
          v-if="isButtonVisible"
          v-tooltip.top="t('action.edit')"/>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import EventService from "@/services/EventService";
import {SHOW_VIAGGIO_DATA_DIALOG, UPDATE_ACTION} from "@/components/common/constants/Events";
import {isEmpty} from "lodash";

const props = defineProps({viaggio: {required: true}});
const isButtonVisible = ref(false);
const {t} = useI18n();

onMounted(() => {
  checkButtonVisibility();
})

const showEditViaggioDialog = () => {
  const item = Object.assign({}, props.viaggio);
  EventService.emit(SHOW_VIAGGIO_DATA_DIALOG, {mode: UPDATE_ACTION, item});
}

const checkButtonVisibility = () => {
  isButtonVisible.value = !isEmpty(props.viaggio) ? props.viaggio.parent : false;
}

</script>

<style scoped>

</style>