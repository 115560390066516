<template>
  <ModalDialog v-model:visible="show" :header="dialogTitle" @hide="onHide" style="width: 100%; height: 100%">

    <div class="p-fluid p-formgrid p-grid">

      <!-- contratto -->
      <div class="p-field p-col-12 p-md-2">
        <label for="contrattoId">{{ t("label.viaggio.contratto") }}</label>
        <Dropdown
            id="contrattoId"
            v-model="viaggio.contrattoId"
            :options="contratti"
            option-label="codice"
            option-value="id"
            :filter="true"
            :placeholder="t('message.select-option')"
            :showClear="true"
            :disabled="!isFieldEnabled('contratto')"
            @change="onContrattoChange"/>
        <ErrorMessages field="contrattoId"/>
      </div>

      <!-- muta -->
      <div class="p-field p-col-12 p-md-2">
        <label for="mutaId">{{ t("label.viaggio.muta") }}</label>
        <Dropdown
            v-model="viaggio.mutaId"
            :options="mute"
            option-label="descrizione"
            option-value="id"
            :filter="true"
            :placeholder="t('message.select-option')"
            :showClear="true"
            :disabled="!isFieldEnabled('mutaId')"/>
        <ErrorMessages field="mutaId"/>
      </div>

    </div>


    <!-- separator -->
    <div style="margin-top: 40px"/>


    <!-- viaggio vuoto -->
    <div class="p-shadow-5">
      <Card>
        <template #title>
          <div class="viaggio-title">{{ t('label.viaggio.direzione.andata') }}</div>
        </template>
        <template #content>
          <ViaggioInterattivoTimeline v-model:origine="viaggioVuoto.origine"
                                      v-model:transito="viaggioVuoto.nodiTransito"
                                      v-model:destinazione="viaggioVuoto.destinazione"/>
        </template>
        <template #footer>


          <!-- date di carico prenotate
          <div class="p-field p-col-12 p-md-2">
            <label for="dateCaricoPrenotate">{{ t("label.viaggio.date-carico-prenotate") }}</label>
            <Calendar id="dateCaricoPrenotate"
                      v-model="viaggio.dateCaricoPrenotate"
                      selection-mode="range"
                      :manual-input="false"
                      :show-icon="true"
                      :numberOfMonths="2"
                      :disabled="!isFieldEnabled('dateCaricoPrenotate')"/>
          </div>-->

          <!-- date di carico previste
          <div class="p-field p-col-12 p-md-2">
            <label for="dateCaricoPreviste">{{ t("label.viaggio.date-carico-previste") }}</label>
            <Calendar id="dateCaricoPreviste"
                      v-model="viaggio.dateCaricoPreviste"
                      selection-mode="range"
                      :manual-input="false"
                      :show-icon="true"
                      :numberOfMonths="2"
                      :disabled="!isFieldEnabled('dateCaricoPreviste')"/>
          </div>-->


        </template>
      </Card>
    </div>

    <!-- separator -->
    <div style="margin-top: 40px"/>

    <!-- viaggio pieno -->
    <div class="p-shadow-10">
      <Card>
        <template #title>
          <div class="viaggio-title">{{ t('label.viaggio.direzione.ritorno') }}</div>
        </template>
        <template #content>
          <ViaggioInterattivoTimeline v-model:origine="viaggioPieno.origine"
                                      v-model:transito="viaggioPieno.nodiTransito"
                                      v-model:destinazione="viaggioPieno.destinazione"/>

        </template>
        <template #footer>
          <!-- date di scarico previste
          <div class="p-field p-col-12 p-md-2">
            <label for="dateScaricoPreviste">{{ t("label.viaggio.date-scarico-previste") }}</label>
            <Calendar id="dateScaricoPreviste"
                      v-model="viaggio.dateScaricoPreviste"
                      selection-mode="range"
                      :manual-input="false"
                      :show-icon="true"
                      :numberOfMonths="2"
                      :disabled="!isFieldEnabled('dateScaricoPreviste')"/>
          </div>-->
        </template>
      </Card>
    </div>

    <!-- separator -->
    <div class="p-mt-6"/>


    <!-- actions -->
    <template #footer>
      <Button :label="t('action.cancel')" icon="pi pi-times" class="p-button p-button-light" @click="hideAction"/>

      <!-- salva -->
      <Button :label="t('action.save')"
              icon="pi pi-check"
              class="p-button p-button-success p-float-right"
              @click="saveViaggioAction"
              v-if="showSalvaViaggioButton()"/>

      <!-- salva date carico -->
      <Button :label="t('action.save')"
              icon="pi pi-check" class="p-button p-button-success p-float-right"
              @click="updateDateCarico"
              v-if="showSalvaDateCarico()"/>

      <!-- salva date scarico -->
      <Button :label="t('action.save')"
              icon="pi pi-check" class="p-button p-button-success p-float-right"
              @click="updateDateScarico"
              v-if="showSalvaDateScarico()"/>

    </template>

  </ModalDialog>
</template>

<script setup>
import {computed, onMounted, provide, ref} from "vue";
import EventService from "@/services/EventService";
import {
  INSERT_ACTION,
  NONE_ACTION,
  SHOW_VIAGGIO_INTERATTIVO_DATA_DIALOG,
  UPDATE_ACTION
} from "@/components/common/constants/Events";
import ViaggioInterattivoTimeline from "@/components/viaggio/interattivo/ViaggioInterattivoTimeline";
import {useI18n} from "vue-i18n";
import ModalDialog from "@/components/common/ModalDialog";
import viaggioRestService from "@/services/ViaggioRestService";
import workflowRestService from "@/services/WorkflowRestService";
import {isEmpty, isNil} from "lodash";
import nodoRestService from "@/services/NodoRestService";
import useDateTime from "@/composable/useDateTime";
import {PIANIFICAZIONE_CHIUSA} from "@/components/common/constants/WorkflowStatus";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {useStore} from "vuex";
import useViaggioModel from "@/components/viaggio/interattivo/useViaggioModel";
import ErrorMessages from "@/components/common/ErrorMessages";


    const {t} = useI18n();
    const {parseDate} = useDateTime();
    const store = useStore();
    const {getDefaultViaggioVuoto, getDefaultViaggioPieno} = useViaggioModel();

    const show = ref(false);
    const mode = ref(NONE_ACTION);

    const mute = computed(() => store.getters["muta/mute"]);
    const contratti = computed(() => store.getters['contratto/items']);
    const currentStatus = ref();

    // viaggio ----------------------------------------
    const viaggio = ref({dateCaricoPrenotate: [], dateCaricoPreviste: [], dateScaricoPreviste: []});
    const stazioniViaggioVuoto = ref([]);
    const stazioniViaggioPieno = ref([]);
    // ------------------------------------------------

    // dto per oggetto viaggio --------------------------
    const viaggioVuoto = ref(getDefaultViaggioVuoto());
    const viaggioPieno = ref(getDefaultViaggioPieno());

    // -----------------------------------------------------

    const rules = {
      mutaId: {required: helpers.withMessage(t("validation.required", {field: t("label.viaggio.muta")}), required)},
      contrattoId: {required: helpers.withMessage(t("validation.required", {field: t("label.viaggio.contratto")}), required)},
    };
    const v$ = useVuelidate(rules, viaggio);


    onMounted(() => {
      store.dispatch("muta/loadAll");
      store.dispatch("contratto/loadAll");
      EventService.on(SHOW_VIAGGIO_INTERATTIVO_DATA_DIALOG, onShow);
    })

    // carica i dati del viaggio
    const onShow = async (item) => {
      show.value = true;
      mode.value = item.mode;

      viaggioVuoto.value = getDefaultViaggioVuoto();
      viaggioPieno.value = getDefaultViaggioPieno();

      if (UPDATE_ACTION === mode.value) {
        viaggio.value = await viaggioRestService.findByCodice(item.item.codice);
        const workflow = await workflowRestService.findByContrattoId(viaggio.value.contrattoId);
        currentStatus.value = workflow.status;

        // stazione origine
        if (!isNil(viaggio.value.stazioneOrigineId)) {
          viaggio.value.stazioneOrigine = await nodoRestService.findById(viaggio.value.stazioneOrigineId);
        }

        // stazione carico
        if (!isNil(viaggio.value.stazioneCaricoId)) {
          viaggio.value.stazioneCarico = await nodoRestService.findById(viaggio.value.stazioneCaricoId);
        }

        // stazione scarico
        if (!isNil(viaggio.value.stazioneScaricoId)) {
          viaggio.value.stazioneScarico = await nodoRestService.findById(viaggio.value.stazioneScaricoId);
        }

        // stazione origine
        /*if (!isNil(viaggio.value.stazioneOrigineId)) {
          viaggio.value.stazioneOrigine = await nodoRestService.findById(viaggio.value.stazioneOrigineId);
        }*/

        // vuoto ( eta / etd / atd)
        viaggio.value.praticaViaggioVuoto.eta = parseDate(viaggio.value.praticaViaggioVuoto.eta);
        viaggio.value.praticaViaggioVuoto.etd = parseDate(viaggio.value.praticaViaggioVuoto.etd);
        viaggio.value.praticaViaggioVuoto.atd = parseDate(viaggio.value.praticaViaggioVuoto.atd);
        viaggio.value.praticaViaggioVuoto.ata = parseDate(viaggio.value.praticaViaggioVuoto.ata);

        // pieno (eta / etd / ata)
        viaggio.value.praticaViaggioPieno.eta = parseDate(viaggio.value.praticaViaggioPieno.eta);
        viaggio.value.praticaViaggioPieno.etd = parseDate(viaggio.value.praticaViaggioPieno.etd);
        viaggio.value.praticaViaggioPieno.atd = parseDate(viaggio.value.praticaViaggioPieno.atd);
        viaggio.value.praticaViaggioPieno.ata = parseDate(viaggio.value.praticaViaggioPieno.ata);

        // dateCaricoPreviste (string -> date)
        viaggio.value.dateCaricoPreviste = [];
        const dataCaricoPrevistaDa = parseDate(viaggio.value.dataCaricoPrevistaDa);
        const dataCaricoPrevistaA = parseDate(viaggio.value.dataCaricoPrevistaA);
        if (!isNil(dataCaricoPrevistaDa)) {
          viaggio.value.dateCaricoPreviste[0] = dataCaricoPrevistaDa;
        }
        if (!isNil(dataCaricoPrevistaA)) {
          viaggio.value.dateCaricoPreviste[1] = dataCaricoPrevistaA;
        }

        // dateScaricoPreviste (string -> date)
        viaggio.value.dateScaricoPreviste = [];
        const dataScaricoPrevistaDa = parseDate(viaggio.value.dataScaricoPrevistaDa);
        const dataScaricoPrevistaA = parseDate(viaggio.value.dataScaricoPrevistaA);
        if (!isNil(dataScaricoPrevistaDa)) {
          viaggio.value.dateScaricoPreviste[0] = dataScaricoPrevistaDa;
        }
        if (!isNil(dataScaricoPrevistaA)) {
          viaggio.value.dateScaricoPreviste[1] = dataScaricoPrevistaA;
        }

        // dataCaricoPrenotata (string -> date)
        viaggio.value.dateCaricoPrenotate = [];
        const dataCaricoPrenotataDa = parseDate(viaggio.value.dataCaricoPrenotataDa);
        const dataCaricoPrenotataA = parseDate(viaggio.value.dataCaricoPrenotataA);
        // console.log("DataCaricoPrenotataDa dataCaricoPrenotataA", dataCaricoPrenotataDa, dataCaricoPrenotataA)
        if (!isNil(dataCaricoPrenotataDa)) {
          viaggio.value.dateCaricoPrenotate[0] = dataCaricoPrenotataDa;
        }
        if (!isNil(dataCaricoPrenotataA)) {
          viaggio.value.dateCaricoPrenotate[1] = dataCaricoPrenotataA;
        }

        // stazioniViaggioVuoto
        const nodiTransitoVuoto = viaggio.value.praticaViaggioVuoto.nodiTransito;
        nodiTransitoVuoto.forEach((nodo) => {
          nodo.ett = parseDate(nodo.ett);
          nodo.att = parseDate(nodo.att);
          stazioniViaggioVuoto.value.push(nodo);
        });

        // stazioniViaggioPieno
        const nodiTransitoPieno = viaggio.value.praticaViaggioPieno.nodiTransito;
        nodiTransitoPieno.forEach((nodo) => {
          nodo.ett = parseDate(nodo.ett);
          nodo.att = parseDate(nodo.att);
          stazioniViaggioPieno.value.push(nodo);
        });

        // trasformazione dati viaggio in dto
        transformViaggioToDto();

      }

    }

    // trasformazione viaggio in oggetti viaggioPieno e viaggioVuoto
    const transformViaggioToDto = () => {

      // viaggioVuoto ------------------------------------------------------------

      // origine
      viaggioVuoto.value.origine.luogo = viaggio.value.stazioneOrigine;
      viaggioVuoto.value.origine.dataStimata = viaggio.value.praticaViaggioVuoto.etd;
      viaggioVuoto.value.origine.dataStimataDisabilitata = false;
      viaggioVuoto.value.origine.dataEffettiva = viaggio.value.praticaViaggioVuoto.atd;
      viaggioVuoto.value.origine.dataEffettivaDisabilitata = false;
      viaggioVuoto.value.origine.note = viaggio.value.stazioneOrigioneNote;

      // nodi transito
      // TODO: implementazione
      stazioniViaggioVuoto.value.forEach((s) => {
        console.log("nodo ", s);
        const nodo = {
          luogoLabel: t('label.viaggio.stazione-transito'),
          luogo: {descrizione: ""},
          dataStimataLabel: t('label.viaggio.stazione-transito.ett'),
          dataStimata: null,
          dataStimataDisabilitata: false,
          dataEffettivaLabel: t('label.viaggio.stazione-transito.att'),
          dataEffettiva: null,
          dataEffettivaDisabilitata: false,
          noteLabel: t('label.note'),
          note: "",
          icona: 'pi pi-ellipsis-h',
        }
        viaggioVuoto.value.nodiTransito.push(nodo);
      });

      // destinazione
      viaggioVuoto.value.destinazione.luogo = {descrizione: viaggio.value.stazioneCarico.descrizione};
      viaggioVuoto.value.destinazione.dataStimata = null;
      viaggioVuoto.value.destinazione.dataStimataDisabilitata = false;
      viaggioVuoto.value.destinazione.dataEffettiva = null;
      viaggioVuoto.value.destinazione.dataEffettivaDisabilitata = false;
      viaggioVuoto.value.destinazione.note = "";
      // end viaggiovuoto -----------------------------------------------------------------


      // viaggioPieno ...
      viaggioPieno.value.origine.luogo = viaggio.value.stazioneCarico;
      viaggioPieno.value.origine.dataStimata = viaggio.value.praticaViaggioPieno.etd;
      viaggioPieno.value.origine.dataStimataDisabilitata = false;
      viaggioPieno.value.origine.dataEffettiva = viaggio.value.praticaViaggioPieno.atd;
      viaggioPieno.value.origine.dataEffettivaDisabilitata = false;
      viaggioPieno.value.origine.note = viaggio.value.stazioneCaricoNote;

      // nodi transito
      // TODO: implementazione
      stazioniViaggioPieno.value.forEach((s) => {
        console.log("nodo ", s);
        const nodo = {
          luogoLabel: t('label.viaggio.stazione-transito'),
          luogo: {descrizione: ""},
          dataStimataLabel: t('label.viaggio.stazione-transito.ett'),
          dataStimata: null,
          dataStimataDisabilitata: false,
          dataEffettivaLabel: t('label.viaggio.stazione-transito.att'),
          dataEffettiva: null,
          dataEffettivaDisabilitata: false,
          noteLabel: t('label.note'),
          note: "",
          icona: 'pi pi-ellipsis-h',
        }
        viaggioPieno.value.nodiTransito.push(nodo);
      });

      // destinazione
      viaggioPieno.value.destinazione.luogo = {descrizione: viaggio.value.stazioneScarico.descrizione};
      viaggioPieno.value.destinazione.dataStimata = null;
      viaggioPieno.value.destinazione.dataStimataDisabilitata = false;
      viaggioPieno.value.destinazione.dataEffettiva = null;
      viaggioPieno.value.destinazione.dataEffettivaDisabilitata = false;
      viaggioPieno.value.destinazione.note = "";

    }


    const onContrattoChange = async (e) => {
      const contrattiSelected = contratti.value.filter(c => c.id === e.value);
      const contrattoSelected = contrattiSelected[0];

      // viaggio vuoto / destinazione = contratto / stazione carico
      const idStazioneCarico = contrattoSelected.idStazioneOrigine;
      const stazioneCarico = await nodoRestService.findById(idStazioneCarico);
      viaggioVuoto.value.destinazione.luogo = !isEmpty(stazioneCarico) ? stazioneCarico : {};
      viaggioVuoto.value.destinazione.note = contrattiSelected.noteStazioneOrigine;

      // viaggio pieno / origine = contratto / stazione carico
      viaggioPieno.value.origine.luogo = viaggioVuoto.value.destinazione.luogo;
      viaggioPieno.value.origine.note = viaggioVuoto.value.destinazione.note;

      // viaggio pieno / destinazione = contratto / stazione scarico
      const idStazioneScarico = contrattoSelected.idStazioneDestinazione;
      const stazioneScarico = await nodoRestService.findById(idStazioneScarico);
      viaggioPieno.value.destinazione.luogo = !isEmpty(stazioneScarico) ? stazioneScarico : {};
      viaggioPieno.value.destinazione.note = contrattoSelected.noteStazioneDestinazione;
    }


    const onHide = () => {
      console.log("onHide ");

    }

    // TODO: implemenetazione
    const isFieldEnabled = (field) => {
      console.log("isFieldEnabled ", field);
      return true;
    }


    const dialogTitle = computed(() => {
      return mode.value === INSERT_ACTION ? t("label.viaggio.titolo.insert") : t("label.viaggio.titolo.edit");
    })

    const hideAction = () => {
      show.value = false;
    }


    const saveViaggioAction = () => {
      console.log("saveViaggioAction ", viaggioVuoto.value);
      /*v$.value.$validate();
      if (!v$.value.$invalid) {
        console.log("viaggio ", viaggio.value);

        // stazioneCaricoId da stazioneCarico
        viaggio.value.stazioneCaricoId = viaggio.value.stazioneCarico.id;

        // stazioneOrigineId da stazioneOrigine
        viaggio.value.stazioneOrigineId = viaggio.value.stazioneOrigine.id;

        // stazioneScaricoId da stazioneScarico
        viaggio.value.stazioneScaricoId = viaggio.value.stazioneScarico.id;

        // date carico previste
        const dateCaricoPreviste = viaggio.value.dateCaricoPreviste;
        if (!isNil(dateCaricoPreviste) && 2 === dateCaricoPreviste.length) {
          viaggio.value.dataCaricoPrevistaDa = dateCaricoPreviste[0];
          viaggio.value.dataCaricoPrevistaA = dateCaricoPreviste[1];
        }

        // date scarico previste
        const dateScaricoPreviste = viaggio.value.dateScaricoPreviste;
        if (!isNil(dateScaricoPreviste) && 2 === dateScaricoPreviste.length) {
          viaggio.value.dataScaricoPrevistaDa = dateScaricoPreviste[0];
          viaggio.value.dataScaricoPrevistaA = dateCaricoPreviste[1];
        }

        // date carico prenotate
        const dateCaricoPrenotate = viaggio.value.dateCaricoPrenotate;
        if (!isNil(dateCaricoPrenotate) && 2 === dateCaricoPrenotate.length) {
          viaggio.value.dataCaricoPrenotataDa = dateCaricoPrenotate[0];
          viaggio.value.dataCaricoPrenotataA = dateCaricoPrenotate[1];
        }

        // nodi transito (vuoto e pieno)
        viaggio.value.praticaViaggioVuoto.nodiTransito = stazioniViaggioVuoto.value;
        viaggio.value.praticaViaggioPieno.nodiTransito = stazioniViaggioPieno.value;

        const fullAction = "viaggio/" + mode.value;
        store.dispatch(fullAction, viaggio.value);
        hideAction();
      }*/
    }

    const updateDateCarico = async () => {
      const dateCaricoPreviste = viaggio.value.dateCaricoPreviste;
      await viaggioRestService.updateDateCarico(viaggio.value, dateCaricoPreviste);
      hideAction();
    }

    const updateDateScarico = async () => {
      const viaggioId = viaggio.value.id;
      const dateScaricoPreviste = viaggio.value.dateScaricoPreviste;
      await viaggioRestService.updateDateScarico(viaggioId, dateScaricoPreviste);
      hideAction();
    }


    // Logiche bottoni salvataggio --------------------------------------------------------
    // TODO: accorpare con i bottoni come componenti ad hoc
    const showSalvaViaggioButton = () => {
      const isPianificazioneChiusa = (PIANIFICAZIONE_CHIUSA === currentStatus.value);
      return store.getters['auth/isSpedizioniere'] && !isPianificazioneChiusa;
    }

    const showSalvaDateCarico = () => {
      return store.getters['auth/isCaricatore'];
    }

    const showSalvaDateScarico = () => {
      return store.getters['auth/isScaricatore']
    }

    /*const showEditViaggioInterattivoDialog = () => {
      show.value = true;
    }*/


    // --------------------------------------------------------------------------------------

    provide('v$', v$);
</script>

<style lang="scss" scoped>

.viaggio-title {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3px;
}

</style>
