<template>
  <TreeTable :value="viaggiTree"
             :expandedKeys="viaggiExpandedKeys"
             :paginator="true"
             :rows="datatableConfig.rows"
             :rowsPerPageOptions="datatableConfig.rowsPerPageOptions"
             :paginatorTemplate="datatableConfig.paginatorTemplate"
             :currentPageReportTemplate="datatableConfig.currentPageReportTemplate"
             responsiveLayout="scroll">

    <!-- numero viaggio -->
    <Column :header="t('label.viaggio.codice')" :expander="true">
      <template #body="slotProps">
        {{ slotProps.node.data.codice }}
      </template>
    </Column>

    <!-- direzione -->
    <Column field="node.data.codice" :header="t('label.viaggio.direzione')" headerStyle="width: 15em">
      <template #body="slotProps">
        <ViaggioDirectionIndicator :direction="slotProps.node.data.direzione"/>
      </template>
    </Column>

    <!-- eta -->
    <Column :header="t('label.viaggio.eta')">
      <template #body="slotProps">
        {{ dateFormat(slotProps.node.data.eta) }}
      </template>
    </Column>

    <!-- etd -->
    <Column :header="t('label.viaggio.etd')">
      <template #body="slotProps">
        {{ dateFormat(slotProps.node.data.etd) }}
      </template>
    </Column>


    <Column headerStyle="width: 20em" headerClass="p-text-center" bodyClass="p-text-center">
      <template #body="slotProps">

        <StartViaggioButton :viaggio="slotProps.node.data"/>
        <ModificaViaggioButton :viaggio="slotProps.node.data" />
        <ViaggioUploadFileDialogButton :viaggio="slotProps.node.data"/>
        <WorkflowButton :viaggio="slotProps.node.data"/>
        <ChiusuraPianificazioneButton :viaggio="slotProps.node.data"/>

        <RipianificazioneButton :viaggio="slotProps.node.data"/>
        <RipianificazioneOperativaButton :viaggio="slotProps.node.data"/>
        <ChiusuraRipianificazioneOperativaButton :viaggio="slotProps.node.data"/>
        <ChiusuraViaggioButton :viaggio="slotProps.node.data"/>
        <SelectImpresaFerroviariaButton :viaggio="slotProps.node.data" />
        <AggiungiWatcherButton :viaggio="slotProps.node.data"/>
        <PianificazioneButton :viaggio="slotProps.node.data"/>

        <DownloadCimButton :viaggio="slotProps.node.data"/>
        <DownloadCuvButton :viaggio="slotProps.node.data"/>
        <DownloadCit23Button :viaggio="slotProps.node.data"/>

      </template>
    </Column>
  </TreeTable>

  <SoggettoRichiestaDataDialog/>

</template>

<script setup>

import {computed, onMounted, onUnmounted, ref} from "vue";
import EventService from "@/services/EventService";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import useDateTime from "@/composable/useDateTime";
import {
  WORKFLOW_NOTIFICATION,
} from "@/components/common/constants/Events";
import DownloadCit23Button from "@/components/viaggio/bottoni/DownloadCit23Button";
import DownloadCuvButton from "@/components/viaggio/bottoni/DownloadCuvButton";
import DownloadCimButton from "@/components/viaggio/bottoni/DownloadCimButton";
import AggiungiWatcherButton from "@/components/viaggio/bottoni/AggiungiWatcherButton";
import ChiusuraViaggioButton from "@/components/viaggio/bottoni/ChiusuraViaggioButton";
import ChiusuraRipianificazioneOperativaButton
  from "@/components/viaggio/bottoni/ChiusuraRipianificazioneOperativaButton";
import RipianificazioneOperativaButton from "@/components/viaggio/bottoni/RipianificazioneOperativaButton";
import RipianificazioneButton from "@/components/viaggio/bottoni/RipianificazioneButton";
import ChiusuraPianificazioneButton from "@/components/viaggio/bottoni/ChiusuraPianificazioneButton";
import ViaggioUploadFileDialogButton from "@/components/viaggio/bottoni/ViaggioUploadFileDialogButton";
import StartViaggioButton from "@/components/viaggio/bottoni/StartViaggioButton";
import ViaggioDirectionIndicator from "@/components/common/ViaggioDirectionIndicator";
import PianificazioneButton from "@/components/viaggio/bottoni/PianificazioneButton";
import WorkflowButton from "@/components/viaggio/bottoni/WorkflowButton";
import ModificaViaggioButton from "@/components/viaggio/bottoni/ModificaViaggioButton";
import SelectImpresaFerroviariaButton from "@/components/viaggio/bottoni/SelectImpresaFerroviariaButton";
import SoggettoRichiestaDataDialog from "@/components/anagrafica/soggetto/richieste/SoggettoRichiestaDataDialog";


const store = useStore();
const {t} = useI18n();
const {dateFormat} = useDateTime();

const datatableConfig = store.getters["config/datatable"];
const viaggiExpandedKeys = ref({});
const viaggi = computed(() => store.getters['viaggio/viaggi']);
const viaggiTree = computed(() => {
  const outputTree = [];

  viaggi.value.forEach(v => {
    const children = [
      {key: v.idPraticaViaggioVuoto, data: v.praticaViaggioVuoto, parent: false},
      {key: v.idPraticaViaggioPieno, data: v.praticaViaggioPieno, parent: false}
    ];
    const viaggioTree = {
      key: v.id,
      data: {id: v.id, codice: v.codice, contrattoId: v.contrattoId, parent: true},
      children: children
    };
    outputTree.push(viaggioTree);
  });

  return outputTree;
});


onMounted(() => {
  store.dispatch("viaggio/loadAll");
  EventService.on(WORKFLOW_NOTIFICATION, () => store.dispatch("viaggio/loadAll"));
});

onUnmounted(() => {
  EventService.off(WORKFLOW_NOTIFICATION, () => store.dispatch("viaggio/loadAll"));
});

</script>

<style scoped>


</style>
