<template>

  <!-- header -->
  <div class="p-fluid formgrid grid mb-3">

    <!-- merce -->
    <div class="field col-12 md:col-3">
      <label for="merce">{{ t('label.viaggio.pianificazione.merce') }}</label>
      <InputText id="merce" type="text" v-model="pianificazioneReale.merce" :disabled="true"/>
    </div>

    <div class="field col-12 md:col-6 lg:col-7"/>

    <div class="field col-12 md:col-2 lg:col-2 mt-4">
      <Button :label="t('action.save')" icon="pi pi-check" class="p-button p-button-success p-float-right"
              @click="savePianificazioneReale"/>
    </div>

  </div>

  <!-- carri -->
  <DataTable :value="pianificazioneReale.items"
             :reorderableColumns="false"
             @rowReorder="onRowReorder"
             :showGridlines="true"
             :stripedRows="true"
             responsiveLayout="scroll">

    <!-- reorder -->
    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderable-column="false" :sortable="false"/>

    <!-- matricola -->
    <Column :header="t('label.carro.matricola')" :reorderable-column="false">
      <template #body="slotProps">
        {{ slotProps.data.carro.matricola }}
      </template>
    </Column>

    <!-- ultime 3 merci inserite -->
    <Column :header="t('label.viaggio.pianificazione.reale.ultime-3-merci')" :reorderable-column="false">
      <template #body="slotProps">
          <span v-if="slotProps.data.carro.ultime3Merci.length > 0">
            <span v-html="formatUltimeMerci(slotProps.data.carro.ultime3Merci)"/>
          </span>
      </template>
    </Column>

    <!-- peso lordo merce -->
    <Column :header="t('label.viaggio.pianificazione.reale.peso-lordo')" :reorderable-column="false">
      <template #body="slotProps">
        <InputNumber v-model="slotProps.data.merce.pesoLordo" @blur="onPesoLordoMerceChange" :showButtons="false"
                     mode="decimal" :minFractionDigits="2"
                     :class="{'p-invalid' : slotProps.data.merce.pesoNetto <= 0}"/>
      </template>
    </Column>

    <!-- tara (vagone) -->
    <Column :header="t('label.viaggio.pianificazione.reale.tara-vagone')" :reorderable-column="false">
      <template #body="slotProps">
        <InputNumber v-model="slotProps.data.carro.tara" :readonly="true" :disabled="true" :showButtons="false"
                     mode="decimal" :minFractionDigits="2"/>
      </template>
    </Column>

    <!-- peso netto -->
    <Column :header="t('label.viaggio.pianificazione.reale.peso-netto')" :reorderable-column="false">
      <template #body="slotProps">
        <InputNumber v-model="slotProps.data.merce.pesoNetto" :readonly="true" :disabled="true"
                     :class="{'p-invalid' : slotProps.data.merce.pesoNetto <= 0}"/>
      </template>
    </Column>

  </DataTable>

</template>
<script setup>
import {useI18n} from "vue-i18n";
import {onMounted, ref} from 'vue';
import pianificazioneRestService from "@/services/PianificazioneRestService";
import useMessage from "@/composable/useMessage";
import useText from "@/composable/useText";

const props = defineProps({item: {}});

const {t} = useI18n();
const {success, error} = useMessage();
const {isNotBlank} = useText();

const viaggio = ref();
const pianificazioneReale = ref([]);

onMounted(async () => {
  viaggio.value = Object.assign({}, props.item);
  pianificazioneReale.value = await pianificazioneRestService.getPianificazioneReale(viaggio.value.id);
});


const onRowReorder = (event) => {
  let itemsOrdinati = [];
  const items = event.value;
  for (let i = 0; i < items.length; i++) {
    let item = items[i];
    item.posizione = (i + 1);
    itemsOrdinati.push(item);
  }

  let pianificazioneRealeOrdinata = [];
  for (let i = 0; i < itemsOrdinati.length; i++) {
    const nextId = itemsOrdinati[i].carro.id;
    const nextItem = pianificazioneReale.value.items.find(p => nextId === p.carro.id);
    nextItem.carro.posizione = (i + 1);
    pianificazioneRealeOrdinata.push(nextItem);
  }
  pianificazioneReale.value.items = pianificazioneRealeOrdinata;
}

const savePianificazioneReale = async () => {
  if (isMerceValid()) {
    const idViaggio = viaggio.value.id;
    await pianificazioneRestService.savePianificazioneReale(idViaggio, pianificazioneReale.value);
    success();
  } else {
    error(t('validation.errors'));
  }
}

const isMerceValid = () => {
  console.log(pianificazioneReale.value.items);
  return !pianificazioneReale.value.items.some(p => p.merce.pesoNetto <= 0);
}

const onPesoLordoMerceChange = async () => {
  pianificazioneReale.value = await pianificazioneRestService.ricalcolaPesoNetto(pianificazioneReale.value);
}


const formatUltimeMerci = (ultimeMerci) => {
  let ultimeMerciCSV = "";
  if (ultimeMerci.length > 0) {
    ultimeMerciCSV = ultimeMerci.map(m => (isNotBlank(m.codiceMerce) ? m.codiceMerce : "") + " " + (isNotBlank(m.descrizione) ? m.descrizione : "")).join("<br/>\n");
  }
  return ultimeMerciCSV;
}

</script>

<style scoped>

::v-deep(.p-datatable-reorderablerow-handle) {
  font-size: 40px;
}

</style>
