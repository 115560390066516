<template>
  <Button icon="pi pi-chart-bar" class="p-button-warning mr-2"
          @click="showTimelineStateDiagramDialog"
          v-if="isButtonVisible"
          v-tooltip.top="t('action.flow-diagram')"/>
</template>

<script setup>
import contrattoRestService from "@/services/ContrattoRestService";
import viaggioRestService from "@/services/ViaggioRestService";
import workflowViaggioRestService from "@/services/WorkflowViaggioRestService";
import EventService from "@/services/EventService";
import {SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG} from "@/components/common/constants/Events";
import {isEmpty} from "lodash";
import {useI18n} from "vue-i18n";
import {onMounted, ref} from "vue";

const props = defineProps({viaggio: {required: true}});
const isButtonVisible = ref(false);
const {t} = useI18n();


onMounted(() => {
  checkButtonVisibility();
})

const showTimelineStateDiagramDialog = async () => {
  const contratto = (await contrattoRestService.findById(props.viaggio.contrattoId)).data;
  const viaggio = await viaggioRestService.findByCodice(props.viaggio.codice);
  const workflow = await workflowViaggioRestService.findStatiByCodiceViaggio(props.viaggio.codice);

  EventService.emit(SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG, {contratto, viaggio, workflow});
}

const checkButtonVisibility = () => {
  isButtonVisible.value = !isEmpty(props.viaggio) ? props.viaggio.parent : false;
}

</script>

<style scoped>

</style>