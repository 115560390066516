<template>
  <ModalDialog v-model:visible="show"
               :header="t('label.viaggio.pianificazione')"
               @hide="onHide"
              style="width: 80vw">
    <TabView class="p-mt-3">
      <TabPanel>
        <template #header>
          <h5 class="p-ml-1">
            <i class="pi pi-circle-off"/>
            {{ t('label.viaggio.pianificazione-reale') }}</h5>
        </template>
        <PianificazioneRealeDialog :item="item"/>
      </TabPanel>
      <TabPanel>
        <template #header>
          <h5 class="p-ml-1">
            <i class="pi pi-table"/>
            {{ t('label.viaggio.pianificazione-analisi') }}</h5>
        </template>
        <PianificazioneDialog :item="item"/>
      </TabPanel>
    </TabView>
  </ModalDialog>
</template>

<script setup>
import PianificazioneDialog from "@/components/viaggio/PianificazioneDialog";
import PianificazioneRealeDialog from "@/components/viaggio/PianificazioneRealeDialog";
import {useI18n} from "vue-i18n";
import ModalDialog from "@/components/common/ModalDialog";
import {onMounted, ref} from "vue";
import EventService from "@/services/EventService";
import {SHOW_PIANIFICAZIONE_DATA_DIALOG} from "@/components/common/constants/Events";


const {t} = useI18n();
const show = ref(false);
const item = ref();

onMounted(() => {
  EventService.on(SHOW_PIANIFICAZIONE_DATA_DIALOG, onShow);
})

const onShow = (it) => {
  show.value = true;
  item.value = it;
}

const onHide = () => {
}

</script>

<style scoped>

</style>
