import httpClient from "@/services/HttpService";

export default {

    findByCodiceViaggio: async (viaggio) => {
        return (await httpClient.get(`/workflow/viaggio/${viaggio.codice}`)).data;
    },
    findStatiByCodiceViaggio: async (codiceViaggio) => {
        return (await httpClient.get(`/workflow/viaggio/stati/${codiceViaggio}`)).data;
    },
    changeStatusViaggio: async (viaggio, status) => {
        return (await httpClient.get(`/workflow/viaggio/${viaggio.codice}/status/change/${status}`));
    },
    registerActionViaggio: async (viaggio, action) => {
        return (await httpClient.get(`/workflow/viaggio/${viaggio.codice}/action/${action}`));
    }
}
