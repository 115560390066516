<template>

  <!-- andata -->
  <span :class="'status-badge status-andata'" v-if="isAndata">
    <span class="mr-1">{{ t('label.viaggio.direzione.andata') }}</span>
    <i class="pi pi-arrow-circle-right"/>
   </span>

  <!-- ritorno -->
  <span :class="'status-badge status-ritorno'" v-if="isRitorno">
    <i class="pi pi-arrow-circle-left"/>
    <span class="ml-1">{{ t('label.viaggio.direzione.ritorno') }}</span>
   </span>

</template>

<script setup>
import {computed} from "vue";
import {ANDATA, RITORNO} from "@/components/common/constants/DirezioniViaggio";
import {useI18n} from "vue-i18n";

const props = defineProps({
  direction: {required: true}
});

const {t} = useI18n();

const isAndata = computed(() => {
  return ANDATA === props.direction;
});

const isRitorno = computed(() => {
  return RITORNO === props.direction;
})

</script>

<style scoped lang="scss">

.status-badge {
  width: 200px;
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-andata {
    background: #FFCDD2;
    color: #D32F2F;
  }

  &.status-ritorno {
    background: #C8E6C9;
    color: #16a772;
  }


}

</style>
