<template>
  <Button icon="pi pi-eye" class="p-button-info mr-2"
          @click="showDialog"
          v-if="isButtonVisible"
          v-tooltip.top="t('action.aggiungi-watcher')"/>

  <ModalDialog v-model:visible="show"
               :header="title"
               position="center"
               :draggable="false"
               :maximizable="false"
               :modal="false"
               @show="onShow"
               @hide="onHide"
               style="width: 50vw; height: 60vh;">

    <!-- add -->
    <div class="formgrid grid mt-2 mb-4">
      <div class="field col-6">

        <div class="p-inputgroup">
          <InputText type="text" v-model="watcher.username" :placeholder="t('label.username')"
                     :class="errorStyleClass(v$, 'username')" autofocus="autofocus"/>
          <Button icon="pi pi-plus" class="p-button-info" @click="addWatcher"/>
        </div>
        <ErrorMessages field="username"/>
      </div>
    </div>

    <!-- watchers -->
    <DataTable :value="watchers">
      <Column field="username" :header="t('label.utente', 2)"></Column>
      <Column field="permessi" :header="t('label.permessi', 2)"></Column>
      <Column>
        <template #body="slotProps">
          <Button @click="disable(slotProps.data)" class="p-button-success"
                  icon="pi pi-eye" v-if="slotProps.data.attivo" v-tooltip.top="t('label.status.abilitato')"/>

          <Button @click="enable(slotProps.data)" class="p-button-warning"
                  icon="pi pi-eye-slash" v-if="!slotProps.data.attivo" v-tooltip.top="t('label.status.disabilitato')"/>

          <Button @click="deleteWatcher(slotProps.data)" class="p-button-danger ml-2"
                  icon="pi pi-trash" v-tooltip.top="t('action.delete')"/>
        </template>
      </Column>
    </DataTable>

  </ModalDialog>

</template>

<script setup>

import {ref, provide, computed, onMounted} from "vue";
import {useI18n} from "vue-i18n";
import ModalDialog from "@/components/common/ModalDialog";
import {email, helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import useCommon from "@/composable/useCommon";
import ErrorMessages from "@/components/common/ErrorMessages";
import watcherRestService from "@/services/WatcherRestService";
import {useStore} from "vuex";

const props = defineProps({viaggio: {required: true}});

const {errorStyleClass} = useCommon();
const {t} = useI18n();
const store = useStore();
const show = ref(false);
const watcher = ref({username: "", reference: ""});
const watchers = ref([]);
const isButtonVisible = ref(false);

const rules = {
  username: {
    required: helpers.withMessage(t("validation.required", {field: t("label.username")}), required),
    email: helpers.withMessage(t("validation.email", {field: t("label.email")}), email)
  }
};
const v$ = useVuelidate(rules, watcher);

const title = computed(() => {
  return t('label.viaggio.watchers.add', {code: props.viaggio.codice});
});

onMounted(() => {
  checkButtonVisibility();
})

function showDialog() {
  show.value = true;
}

async function addWatcher() {
  await v$.value.$validate();
  if (!v$.value.$invalid) {
    watcher.value.reference = props.viaggio.codice;
    await watcherRestService.insert(watcher.value);

    await resetWatcher();
    await loadAllWatchers();
  }
}

async function onShow() {
  await loadAllWatchers();
}

function onHide() {
  v$.value.$reset();
}

const resetWatcher = async () => {
  await v$.value.$reset();
  watcher.value.username = "";
}

const disable = async (item) => {
  await watcherRestService.disable(item.id);
  await loadAllWatchers();
}

const enable = async (item) => {
  await watcherRestService.enable(item.id);
  await loadAllWatchers();
}

const deleteWatcher = async (item) => {
  await watcherRestService.delete(item.id);
  await loadAllWatchers();
}

const loadAllWatchers = async () => {
  watchers.value = await watcherRestService.findAll(props.viaggio.codice);
}

const checkButtonVisibility = async () => {
  const isExecutionManager = store.getters['auth/isExecutionManager'];
  const isSpedizioniere = store.getters['auth/isSpedizioniere'];
  const isInViaggioContainer = props.viaggio.parent;
  isButtonVisible.value = isInViaggioContainer && (isExecutionManager || isSpedizioniere);
}

provide("v$", v$);
</script>

<style lang="scss" scoped>

.status {
  font-size: 1.3em;

  &.active {
    color: #00bcd4;
  }

  &.disabled {
    color: #a31545;
  }

}


</style>