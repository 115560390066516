<template>
  <Button icon="pi pi-download" class="p-button-success mr-2"
          @click="cimDownload(viaggio)"
          v-if="isButtonVisible(viaggio)"
          v-tooltip.top="t('action.cim.download')"/>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import viaggioRestService from "@/services/ViaggioRestService";
import {isEmpty} from "lodash";
import {RITORNO} from "@/components/common/constants/DirezioniViaggio";
import {useStore} from "vuex";


defineProps({viaggio: {type: Object, required: true}});

const {t} = useI18n();
const store = useStore();

const cimDownload = (viaggio) => {
  viaggioRestService.downloadCim(viaggio);
}

const isButtonVisible = (viaggio) => {
  const isSpedizioniere = store.getters['auth/isSpedizioniere'];
  const isRitorno = !isEmpty(viaggio) ? (RITORNO === viaggio.direzione) : false;
  return isSpedizioniere && isRitorno;
}

</script>

<style scoped>

</style>
