import httpClient from "@/services/HttpService";

export default {

    getPianificazioneReale: async (praticaId) => {
        return (await httpClient.get(`/pianificazione/reale/${praticaId}`)).data;
    },
    ricalcolaPesoNetto: async (pianificazioneReale) => {
        return (await httpClient.post(`/pianificazione/reale/peso/netto`, pianificazioneReale)).data;
    },
    linee: async () => {
        return (await httpClient.get('/pianificazione/linee')).data;
    },
    computeTotals: async (data) => {
        return (await httpClient.post('/pianificazione/compute/totals', data)).data;
    },
    save: async (data) => {
        return (await httpClient.post('/pianificazione/', data)).data;
    },
    savePianificazioneReale: async (praticaId, data) => {
        return (await httpClient.post(`/pianificazione/reale/${praticaId}`, data)).data;
    },
    chiudiPianificazione: async (viaggioId, data) => {
        return (await httpClient.get(`/pianificazione/chiudi/${viaggioId}`, data)).data;
    },
    riapriPianificazione: async (viaggioId) => {
        return (await httpClient.get(`/pianificazione/riapri/${viaggioId}`)).data;
    },
    isPianificazioneCloseable: async (codiceViaggio) => {
        return (await httpClient.get(`/pianificazione/${codiceViaggio}/closeable`)).data;
    },
    isPianificazioneChiusa: async (codiceViaggio) => {
        return (await httpClient.get(`/pianificazione/${codiceViaggio}/chiusa`)).data;
    },
    isRipianificabile: async (codiceViaggio) => {
        return (await httpClient.get(`/pianificazione/${codiceViaggio}/ripianificabile`)).data;
    },
    isRipianificazioneOperativaAttivabile: async (codiceViaggio) => {
        return (await httpClient.get(`/pianificazione/operativa/attivabile/${codiceViaggio}`)).data;
    },
    isChiusuraRipianificazioneOperativaAttivabile: async (codiceViaggio) => {
        return (await httpClient.get(`/pianificazione/operativa/chiusura/attivabile/${codiceViaggio}`)).data;
    },
    isRipianificazioneOperativa: async (codiceViaggio) => {
        return (await httpClient.get(`/pianificazione/${codiceViaggio}/ripianificazione/operativa`)).data;
    },
    riapriPianificazioneOperativa: async (codiceViaggio) => {
        return (await httpClient.get(`/pianificazione/operativa/riapri/${codiceViaggio}`)).data;
    },
    chiudiPianificazioneOperativa: async (codiceViaggio) => {
        return (await httpClient.get(`/pianificazione/operativa/chiudi/${codiceViaggio}`)).data;
    }

}
