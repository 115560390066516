<template>

  <!-- toolbar -->
  <Toolbar class="mb-4">
    <template #start>
      <Button :label="t('action.new')"
              icon="pi pi-plus"
              class="p-button-success mr-2"
              @click="showNewViaggioDialog"
              v-if="isNewViaggioButtonVisible()"/>
    </template>
    <template #end>
      <!--<ScheduleTimelineCalendarDialog v-if="checkPermission([Roles.SPEDIZIONIERE])"/>-->
    </template>
  </Toolbar>

  <div class="card">
    <TabView @tab-change="onTabChange">
      <TabPanel>
        <template #header>
          <i class="pi pi-directions tab-icon mr-1"/>
          <span class="uppercase">{{ t('label.viaggio.mio', 2) }}</span>
        </template>
        <ViaggioTableTab/>
      </TabPanel>
      <TabPanel>
        <template #header>
          <i class="pi pi-eye tab-icon mr-1"/>
          <span class="uppercase">{{ t('label.viaggio.watching') }}</span>
        </template>
        <ViaggioWatchTab/>
      </TabPanel>
    </TabView>
  </div>


  <ViaggioDataDialog/>
  <ContrattoDataDialog/>
  <ViaggioInterattivoDataDialog/>
  <PianificazioniDialog/>
  <ConfirmDialog/>
  <TimelineStateDiagramDialog/>

</template>

<script setup>
import EventService from "@/services/EventService";
import {
  INSERT_ACTION,
  SHOW_VIAGGI_WATCHING_TAB,
  SHOW_VIAGGIO_DATA_DIALOG,
} from "@/components/common/constants/Events";

import ViaggioInterattivoDataDialog from "@/components/viaggio/interattivo/ViaggioInterattivoDataDialog";
import PianificazioniDialog from "@/components/viaggio/interattivo/PianificazioniDialog";
import TimelineStateDiagramDialog from "@/components/common/timeline/TimelineStateDiagramDialog";
import ContrattoDataDialog from "@/components/contratto/ContrattoDataDialog";
import ViaggioDataDialog from "@/components/viaggio/ViaggioDataDialog";
import ViaggioWatchTab from "@/components/viaggio/ViaggioWatchTab";
import ViaggioTableTab from "@/components/viaggio/ViaggioTableTab";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const store = useStore();
const {t} = useI18n();

const onTabChange = (evt) => {
  if (1 === evt.index) {
    EventService.emit(SHOW_VIAGGI_WATCHING_TAB, {});
  }
}

const showNewViaggioDialog = () => {
  EventService.emit(SHOW_VIAGGIO_DATA_DIALOG, {mode: INSERT_ACTION, item: {}});
}

const isNewViaggioButtonVisible = () => {
  return store.getters['auth/isSpedizioniere'];
}

</script>

<style scoped>
.tab-icon {
  font-size: 1.5rem;
  font-weight: bold;
}

</style>