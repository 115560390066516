<template>

  <!-- header -->
  <div class="p-fluid formgrid grid mb-3">

    <!-- merce -->
    <div class="field col-12 md:col-3">
      <label for="merce">{{ t('label.viaggio.pianificazione.merce') }}</label>
      <InputText id="merce" type="text" v-model="pianificazione.merce" :disabled="true"/>
    </div>

    <div class="field col-12 md:col-4"/>

    <!-- peso netto -->
    <div class="field col-12 md:col-2 text-center mr-2">
      <p class="badge-label">{{ t('label.viaggio.pianificazione.peso-netto') }}</p>
      <Badge :value="decimalFormat(pianificazione.pesoNetto)" severity="info" size="xlarge" class="shadow-4"/>
    </div>

    <!-- peso lordo -->
    <div class="field col-12 md:col-2 text-center">
      <p class="badge-label">{{ t('label.viaggio.pianificazione.peso-lordo') }}</p>
      <Badge :value="decimalFormat(pianificazione.pesoLordo)" severity="info" size="xlarge" class="shadow-4"/>
    </div>

    <!-- peso -->
    <div class="field col-12 md:col-2">
      <label for="peso">{{ t('label.viaggio.pianificazione.peso') }}</label>
      <InputNumber id="peso" v-model="filtri.peso" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"/>
    </div>

    <!-- tolleranza -->
    <div class="field col-12 md:col-2">
      <label for="tolleranza">{{ t('label.viaggio.pianificazione.tolleranza') }}</label>
      <InputNumber id="tolleranza" v-model="filtri.tolleranza" mode="decimal" :minFractionDigits="2"
                   :maxFractionDigits="2" suffix=" %"/>
    </div>

    <!-- linea -->
    <div class="field col-12 md:col-2">
      <label for="linea">{{ t('label.viaggio.pianificazione.linea') }}</label>
      <Dropdown id="linea"
                v-model="filtri.linea"
                :options="linee"
                option-label="label"
                option-value="value"
                :filter="true"
                :placeholder="t('message.select-option')"
                :showClear="true"/>
    </div>

    <!-- button -->
    <div class="field col-12 md:col-2 pt-4">
      <Button icon="pi pi-refresh" @click="computeTotals" :disabled="!isButtonEnabled"
              class="p-button-rounded p-button-warning shadow-1"/>
    </div>

    <div class="field col-12 md:col-1">
    </div>


  </div>

  <!-- carri -->
  <DataTable :value="pianificazione.carri"
             :reorderableColumns="false"
             @rowReorder="onRowReorder"
             :showGridlines="true"
             :stripedRows="true"
             responsiveLayout="scroll">

    <!-- reorder -->
    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderable-column="false" :sortable="false">
      <template #footer>
        {{ decimalFormat(pianificazione.totaleCarri) }}
      </template>
    </Column>

    <!-- position -->
    <Column :header="t('label.position')" :sortable="false" :reorderable-column="false">
      <template #body="slotProps">
        <Badge :value="slotProps.data.posizione" size="large" severity="success"></Badge>
      </template>

    </Column>

    <!-- matricola -->
    <Column field="matricola" :header="t('label.carro.matricola')" :reorderable-column="false" style="width: 180px"/>

    <!-- portata carro (ton) -->
    <Column field="portataTon" :header="t('label.carro.portata')" :reorderable-column="false"/>

    <!-- volume (litri) -->
    <Column :header="t('label.carro.volumeL')" :reorderable-column="false">
      <template #body="slotProps">
        {{ decimalFormat(slotProps.data.volumeL) }}
      </template>
      <template #footer>
        {{ decimalFormat(pianificazione.totaleVolume) }}
      </template>
    </Column>

    <!-- tara -->
    <Column :header="t('label.carro.tara')" :reorderable-column="false">
      <template #body="slotProps">
        {{ decimalFormat(slotProps.data.tara) }}
      </template>
      <template #footer>
        {{ decimalFormat(pianificazione.totaleTare) }}
      </template>
    </Column>

    <!-- portata teorica carro C 80 ton -->
    <Column :header="t('label.carro.portataCTeorica80Ton')" :reorderable-column="false">
      <template #body="slotProps">
        {{ decimalFormat(slotProps.data.portataC80Ton) }}
      </template>
      <template #footer>
        {{ decimalFormat(pianificazione.totaleC80Ton) }}
      </template>
    </Column>

    <!-- portata teorica carro C con tolleranza 80 ton -->
    <Column :header="t('label.carro.portataCTeoricaWithTolerance80Ton')" :reorderable-column="false">
      <template #body="slotProps">
        {{ decimalFormat(slotProps.data.portataC80TonTolleranza) }}
      </template>
      <template #footer>
        {{ decimalFormat(pianificazione.totaleC80TonTolleranza) }}
      </template>
    </Column>

    <!-- portata teorica carro C 82 ton -->
    <Column :header="t('label.carro.portataCTeorica82Ton')" :reorderable-column="false" v-if="isColonnaVisible('82')">
      <template #body="slotProps">
        {{ decimalFormat(slotProps.data.portataC82Ton) }}
      </template>
      <template #footer>
        {{ decimalFormat(pianificazione.totaleC82Ton) }}
      </template>
    </Column>

    <!-- portata teorica carro C con tolleranza 82 ton -->
    <Column :header="t('label.carro.portataCTeoricaWithTolerance82Ton')" :reorderable-column="false"
            v-if="isColonnaVisible('82')">
      <template #body="slotProps">
        {{ decimalFormat(slotProps.data.portataC82TonTolleranza) }}
      </template>
      <template #footer>
        {{ decimalFormat(pianificazione.totaleC82TonTolleranza) }}
      </template>
    </Column>

    <!-- portata teorica carro D -->
    <Column :header="t('label.carro.portata-d-teorica')" :reorderable-column="false" v-if="isColonnaVisible('90')">
      <template #body="slotProps">
        {{ decimalFormat(slotProps.data.portataDTon) }}
      </template>
      <template #footer>
        {{ decimalFormat(pianificazione.totaleDTon) }}
      </template>
    </Column>

    <!-- portata teorica carro D con tolleranza -->
    <Column :header="t('label.carro.portata-d-teorica-tolleranza')" :reorderable-column="false"
            v-if="isColonnaVisible('90')">
      <template #body="slotProps">
        {{ decimalFormat(slotProps.data.portataDTonTolleranza) }}
      </template>
      <template #footer>
        {{ decimalFormat(pianificazione.totaleDTonTolleranza) }}
      </template>
    </Column>

    <!-- portata teorica carro 84 ton -->
    <Column :header="t('label.carro.portata-84-teorica')" :reorderable-column="false" v-if="isColonnaVisible('84')">
      <template #body="slotProps">
        {{ decimalFormat(slotProps.data.portata84Ton) }}
      </template>
      <template #footer>
        {{ decimalFormat(pianificazione.totale84Ton) }}
      </template>
    </Column>

    <!-- portata teorica carro 84 ton con tolleranza -->
    <Column :header="t('label.carro.portata-84-teorica-tolleranza')" :reorderable-column="false"
            v-if="isColonnaVisible('84')">
      <template #body="slotProps">
        {{ decimalFormat(slotProps.data.portata84TonTolleranza) }}
      </template>
      <template #footer>
        {{ decimalFormat(pianificazione.totale84TonTolleranza) }}
      </template>
    </Column>


    <!-- portata in funzione dei m3 in ton con tolleranza -->
    <Column :header="t('label.carro.portata-m3-tolerance')" :reorderableColumn="false">
      <template #body="slotProps">
        {{ decimalFormat(slotProps.data.portataM3Tolleranza) }}
      </template>
      <template #footer>
        {{ decimalFormat(pianificazione.totaleM3Tolleranza) }}
      </template>
    </Column>

    <!-- portata reale -->
    <Column :header="t('label.viaggio.pianificazione.carro.portata-reale')" :reorderableColumn="false">
      <template #body="slotProps">
        {{ decimalFormat(slotProps.data.portataReale) }}
      </template>
      <template #footer>
        {{ decimalFormat(pianificazione.totalePortataReale) }}
      </template>
    </Column>

    <!-- peso lordo reale -->
    <Column field="pesoLordoReale" :header="t('label.viaggio.pianificazione.carro.peso-lordo-reale')"
            :reorderableColumn="false">
      <template #body="slotProps">
        {{ decimalFormat(slotProps.data.pesoLordoReale) }}
      </template>
      <template #footer>
        {{ decimalFormat(pianificazione.totalePesoLordoReale) }}
      </template>
    </Column>

  </DataTable>


  <!-- actions -->
  <div class="p-fluid formgrid grid mt-4">
    <div class="field col-12 md:col-10 lg:col-11"/>
    <!--    <div class="field col-12 md:col-2 p-lg-1">-->
    <!--      <Button :label="t('action.save')" icon="pi pi-check" class="p-button p-button-success p-float-right"-->
    <!--              @click="savePianificazioneAction"/>-->
    <!--    </div>-->
  </div>

</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import pianificazioneRestService from "@/services/PianificazioneRestService";
import useNumber from "@/composable/useNumber";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";


const props = defineProps({item: {}});

const {t} = useI18n();
// const {success} = useMessage();
const {decimalFormat} = useNumber();

const viaggio = ref({});
const filtri = ref({peso: 1, tolleranza: 1});
const linee = ref([]);
const pianificazione = ref({pesoNetto: 0, pesoLordo: 0, totalePortataReale: 0});

const rules = {
  peso: {required},
  tolleranza: {required},
  linea: {required}
};
const v$ = useVuelidate(rules, filtri);

onMounted(async () => {
  viaggio.value = props.item;
  linee.value = await pianificazioneRestService.linee();
  filtri.value.linea = linee.value[0].value;
  await computeTotals();
});


const onRowReorder = (event) => {
  let carriRiordinati = [];
  const carri = event.value;
  for (let i = 0; i < carri.length; i++) {
    let carro = carri[i];
    carro.posizione = (i + 1);
    carriRiordinati.push(carro);
  }
  pianificazione.value.carri = carriRiordinati;
}


/*const savePianificazioneAction = async () => {
  pianificazione.value.pratica = viaggio.value;
  await pianificazioneRestService.save(pianificazione.value);
  success();
}*/

const computeTotals = async () => {
  const data = {...filtri.value, ...{viaggio: viaggio.value}};
  pianificazione.value = await pianificazioneRestService.computeTotals(data);
}

const isButtonEnabled = computed(() => {
  v$.value.$validate();
  return !v$.value.$invalid;
});

const isColonnaVisible = (line) => {
  return line === filtri.value.linea;
};


</script>

<style scoped>
::v-deep(.p-datatable-reorderablerow-handle) {
  font-size: 40px;
}

.badge-label {
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
}

</style>
