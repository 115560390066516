<template>
  <Button icon="pi pi-compass" class="p-button-warning mr-2"
          @click="showDialog"
          v-if="isButtonVisible"
          v-tooltip.top="t('action.ripianificazione')"/>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import {useConfirm} from "primevue/useconfirm";
import pianificazioneRestService from "@/services/PianificazioneRestService";
import {onMounted, onUnmounted, ref} from "vue";
import EventService from "@/services/EventService";
import {WORKFLOW_NOTIFICATION} from "@/components/common/constants/Events";

const props = defineProps({
  viaggio: {type: Object, required: true}
});

const {t} = useI18n();
const confirm = useConfirm();

const isButtonVisible = ref(false);

onMounted(() => {
  checkButtonVisibility();
  EventService.on(WORKFLOW_NOTIFICATION, checkButtonVisibility);
})

onUnmounted(() => {
  EventService.off(WORKFLOW_NOTIFICATION, checkButtonVisibility);
})

const showDialog = () => {
  confirm.require({
    header: t('message.generic-confirm.header'),
    message: t('message.planning-reopen-confirm'),
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      pianificazioneRestService.riapriPianificazione(props.viaggio.codice);
    },
    reject: () => {
    }
  });
}

const checkButtonVisibility = async () => {
  const isInParentTreeTable = props.viaggio.parent;
  if (isInParentTreeTable) {
    isButtonVisible.value = await pianificazioneRestService.isRipianificabile(props.viaggio.codice);
  }
}

</script>

<style scoped>

</style>
