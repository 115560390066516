<template>

  <Button
      icon="pi pi-paperclip"
      class="p-button-secondary mr-2"
      @click="showUploadFilesDialog"
      v-tooltip.top="t('label.document', 2)"
      v-if="isUploadButtonViaggioVisible()"/>

  <ModalDialog v-model:visible="show" :header="t('label.document',2)">
    <UploadFile :upload-action="doUpload" :uploaded-files="uploadedFiles" :delete-file-action="deleteFile"
                context="viaggio"/>
    <template #footer>
      <Button :label="t('action.cancel')" icon="pi pi-times" class="p-button p-button-light" @click="hide"/>
    </template>

  </ModalDialog>
</template>

<script setup>
import {ref} from "vue";
import ModalDialog from "@/components/common/ModalDialog";
import UploadFile from "@/components/common/UploadFile";
import useMessage from "@/composable/useMessage";
import viaggioRestService from "@/services/ViaggioRestService";
import {isEmpty} from "lodash";
import {useI18n} from "vue-i18n";

const props = defineProps({viaggio: {}})

const {successWithKey} = useMessage();
const {t} = useI18n();

const show = ref(false);
const uploadedFiles = ref([]);
let item = ref({});

const onShow = async () => {
  show.value = true;
  item.value = props.viaggio;
  await loadUploadedFiles();
}

const hide = () => {
  show.value = false;
}

const doUpload = async (data) => {
  const documentType = data.documentType;
  await viaggioRestService.uploadFile(item.value, documentType, data);
  await loadUploadedFiles();
  successWithKey('message.upload-success');
}

const loadUploadedFiles = async () => {
  uploadedFiles.value = await viaggioRestService.uploadedFiles(item.value);
}

const deleteFile = async (data) => {
  const viaggio = item.value;
  const fileName = data.nome;
  await viaggioRestService.deleteFile(viaggio, fileName);
  successWithKey('message.delete-success');
  loadUploadedFiles();
}

const showUploadFilesDialog = () => {
  onShow();
};

const isUploadButtonViaggioVisible = () => {
  return !isEmpty(props.viaggio) ? props.viaggio.parent : false;
}

</script>

<style scoped>

</style>
