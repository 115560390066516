import {useI18n} from "vue-i18n";

export default function () {
    const {t} = useI18n();

    const getDefaultViaggioVuoto = () => {
        return {
            origine: {
                luogoLabel: t('label.viaggio.vuoto.stazione-origine'),
                luogo: {descrizione: ""},
                dataStimataLabel: t('label.viaggio.etd'),
                dataStimata: null,
                dataStimataDisabilitata: false,
                dataEffettivaLabel: t('label.viaggio.atd'),
                dataEffettiva: null,
                dataEffettivaDisabilitata: false,
                noteLabel: t('label.note'),
                note: "",
                icona: "pi pi-circle-off",
                coloreIcona:"red"
            },
            nodiTransito: [],
            destinazione: {
                luogoLabel: t('label.contratto.stazioni-ferrovia.stazione-carico'),
                luogo: {descrizione: ""},
                dataStimataLabel: t('label.viaggio.etd'),
                dataStimata: null,
                dataStimataDisabilitata: false,
                dataEffettivaLabel: t('label.viaggio.atd'),
                dataEffettiva: null,
                dataEffettivaDisabilitata: false,
                noteLabel: t('label.note'),
                note: "",
                icona: "pi pi-cloud-upload",
            }
        };
    }

    const getDefaultViaggioPieno = () => {
        return {
            origine: {
                luogoLabel: t('label.contratto.stazioni-ferrovia.stazione-carico'),
                luogo: {descrizione: ""},
                dataStimataLabel: t('label.viaggio.etd'),
                dataStimata: null,
                dataStimataDisabilitata: false,
                dataEffettivaLabel: t('label.viaggio.atd'),
                dataEffettiva: null,
                dataEffettivaDisabilitata: false,
                noteLabel: t('label.note'),
                note: "",
                icona: "pi pi-cloud-upload",
            },
            nodiTransito: [],
            destinazione: {
                luogoLabel: t('label.contratto.stazioni-ferrovia.stazione-scarico'),
                luogo: {descrizione: ""},
                dataStimataLabel: t('label.viaggio.etd'),
                dataStimata: null,
                dataStimataDisabilitata: false,
                dataEffettivaLabel: t('label.viaggio.atd'),
                dataEffettiva: null,
                dataEffettivaDisabilitata: false,
                noteLabel: t('label.note'),
                note: "",
                icona: "pi pi-circle-on",
            }
        };
    }

    return {getDefaultViaggioPieno, getDefaultViaggioVuoto}
}
