import httpClient from "@/services/HttpService";

export default {

    items: () => {
        return httpClient.get('/workflow/all');
    },
    findByContrattoId: async (contrattoId) => {
        return (await httpClient.get(`/workflow/contratto/${contrattoId}`)).data;
    },
    statuses: () => {
        return httpClient.get('/workflow/statuses');
    },
    changeStatus: (item) => {
        return httpClient.post("/workflow/status/change", item)
    },

    /*insert: (item) => {
       return httpClient.post('/workflow/insert', item)
   },*/
    /*isPianificazioneClosed: async (viaggioId) => {
        return (await httpClient.get(`/workflow/check/status/pianificazione/${viaggioId}/closed`)).data;
    },*/
     /*update: (item) => {
        return httpClient.post('/workflow/update', item);
    },*/
      /*delete: (item) => {
        return httpClient.post('/workflow/delete', item);
    },*/
}
