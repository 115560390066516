<template>
  <Button icon="pi pi-lock" class="p-button-help mr-2"
          @click="showDialog"
          v-if="isButtonVisible"
          v-tooltip.top="t('action.chiusura-ripianificazione-operativa')"/>
</template>

<script setup>
import pianificazioneRestService from "@/services/PianificazioneRestService";
import {useI18n} from "vue-i18n";
import {useConfirm} from "primevue/useconfirm";
import {onMounted, onUnmounted, ref} from "vue";
import EventService from "@/services/EventService";
import {WORKFLOW_NOTIFICATION} from "@/components/common/constants/Events";


const props = defineProps({viaggio: {type: Object, required: true}});

const confirm = useConfirm();
const {t} = useI18n();

const isButtonVisible = ref(false);

onMounted(() => {
  checkButtonVisibility();
  EventService.on(WORKFLOW_NOTIFICATION, checkButtonVisibility);
})

onUnmounted(() => {
  EventService.off(WORKFLOW_NOTIFICATION, checkButtonVisibility);
})

const showDialog = () => {
  confirm.require({
    message: t('message.operational-planning.close-confirm'),
    header: t('message.generic-confirm.header'),
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      pianificazioneRestService.chiudiPianificazioneOperativa(props.viaggio.codice);
    },
    reject: () => {
    }
  });
}

const checkButtonVisibility = async () => {
  const isInParentTreeTable = props.viaggio.parent;
  if (isInParentTreeTable) {
    isButtonVisible.value = await pianificazioneRestService.isChiusuraRipianificazioneOperativaAttivabile(props.viaggio.codice);
  }
}
</script>

<style scoped>

</style>
