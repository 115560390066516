<template>
  <Button icon="pi pi-caret-right" class="p-button-danger mr-2"
          @click="showStartConfirmDialog(viaggio)"
          v-if="visible"
          v-tooltip.top="t('action.viaggio.start')"/>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import viaggioRestService from "@/services/ViaggioRestService";
import {isEmpty} from "lodash";
import {useConfirm} from "primevue/useconfirm";
import {useStore} from "vuex";
import EventService from "@/services/EventService";
import {WORKFLOW_NOTIFICATION} from "@/components/common/constants/Events";


const props = defineProps({viaggio: {}})

const {t} = useI18n();
const confirm = useConfirm();
const store = useStore();

const visible = ref(false);

onMounted(() => {
  checkButtonVisibility();
  EventService.on(WORKFLOW_NOTIFICATION, checkButtonVisibility);
})

const checkButtonVisibility = async () => {
  const isViaggioContainer = !isEmpty(props.viaggio) ? props.viaggio.parent : false;
  const isSpedizioniere = store.getters["auth/isSpedizioniere"];
  if (isViaggioContainer && isSpedizioniere) {
    const isStartable = await viaggioRestService.isStartable(props.viaggio);
    visible.value = isStartable;
  } else {
    visible.value = false;
  }
}

const showStartConfirmDialog = (viaggio) => {
  confirm.require({
    header: t('action.viaggio.start'),
    message: t('message.generic-confirm'),
    icon: 'pi pi-exclamation-triangle',
    accept: async () => {
      await viaggioRestService.start(viaggio);
      await checkButtonVisibility();
    },
    reject: () => {
    }
  });
}

</script>

<style scoped>

</style>
