<template>
  <Button icon="pi pi-lock" class="p-button-info mr-2"
          @click="showDialog"
          v-if="isButtonVisible"
          v-tooltip.top="t('action.chiusura-viaggio')"/>

  <ModalDialog :header="t('label.viaggio.close.title')" v-model:visible="show" style="width: 400px; height: 400px"
               position="center">

    <!-- checks -->
    <div v-for="(value, name) of checks" :key="name" class="field-checkbox mt-2">
      <BooleanIndicator :id="name" :name="name" :status="value"/>
      <label :for="name">{{ name }}</label>
    </div>

    <template #footer>
      <span class="flex justify-content-between flex-wrap">
        <Button :label="t('action.exit')" @click="hide" class="p-button-rounded p-button-success"/>
        <Button :label="t('action.viaggio.close')" @click="closeViaggio" class="p-button-rounded p-button-danger p-text-right"/>
      </span>
    </template>

  </ModalDialog>
</template>


<script setup>
import {ref, onMounted, onUnmounted} from "vue";
import EventService from "@/services/EventService";
import {WORKFLOW_NOTIFICATION} from "@/components/common/constants/Events";
import {useI18n} from "vue-i18n";
import viaggioRestService from "@/services/ViaggioRestService";
import ModalDialog from "@/components/common/ModalDialog";
import BooleanIndicator from "@/components/common/BooleanIndicator";


const props = defineProps({viaggio: {required: true, type: Object}});

const {t} = useI18n();

const show = ref(false);
const isButtonVisible = ref(false);
const checks = ref([]);

onMounted(() => {
  checkButtonVisibility();
  EventService.on(WORKFLOW_NOTIFICATION, checkButtonVisibility);
})

onUnmounted(() => {
  EventService.off(WORKFLOW_NOTIFICATION, checkButtonVisibility);
})

const showDialog = () => {
  show.value = true;
}

const checkButtonVisibility = async () => {
  const isInParentTreeTable = props.viaggio.parent;
  if (isInParentTreeTable) {
    isButtonVisible.value = await viaggioRestService.isViaggioCloseable(props.viaggio);
    if (isButtonVisible.value) {
      checks.value = await viaggioRestService.chiudiCheck(props.viaggio);
    }

  }
}

const hide = () => {
  show.value = false;
}

const closeViaggio = () => {
  viaggioRestService.chiudiViaggio(props.viaggio);
  hide()
}

</script>

<style scoped>

</style>
